import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { apiHandleSideEffects, IWordPressResponse } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import * as React from "react";
import { useEffect, useState } from 'react';
import { brandName } from 'theme/libs/config';
import { StaticTemplate } from '../StaticTemplate';
import './FourOhFour.styles.less';
import { FourOhFourProps } from "./FourOhFour.types";

export const FourOhFourComponent = (props: FourOhFourProps) => {
    const [, isLoading, data] = apiHandleSideEffects(`${apiConfig.host}/wp-json/wp/v2/posts?per_page=5`);
    const [posts, setPosts] = useState<IPost[] | IWordPressResponse | null>(null);
    useEffect(() => {
        setPosts(data);
    }, [data]);

    return (
        <>
            {isLoading &&
                <SpinnerCss />
            }
            {!isLoading && !!posts &&
                <Page
                    className="FourOhFour"
                    description={`${brandName} - 404`}
                    robots={{ follow: false, index: false }}
                    title={`${brandName} - 404`}
                >
                    <StaticTemplate>
                        <h1>Oops! That page can’t be found.</h1>
                        <h3>It looks like nothing was found at this location. Maybe try one of the links below?</h3>
                        <ul>
                            {
                                (posts as IPost[]).map((post: IPost, i: number) =>
                                    <li key={post.id}>
                                        <a
                                            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                                            href={post.link}
                                        />
                                    </li>
                                )
                            }
                        </ul>
                    </StaticTemplate>
                </Page>
            }
        </>
    );
}

interface IPost {
    content: IPostOption;
    date: string;
    id: number;
    link: string;
    title: IPostOption;
}

interface IPostOption {
    protected?: boolean;
    rendered: string;
}
