import { connect } from 'react-redux';
import { VideoListComponent } from './VideoList.component';
import {IVideoListDispatchProps, IVideoListProps, IVideoListStateProps, VideoListProps} from "./VideoList.types";
// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IVideoListProps):IVideoListStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IVideoListProps):IVideoListDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IVideoListStateProps, dispatch:IVideoListDispatchProps, props:IVideoListProps):VideoListProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const VideoList = connect<IVideoListStateProps, IVideoListDispatchProps, IVideoListProps, VideoListProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(VideoListComponent);