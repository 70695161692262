import { connect } from "react-redux";
import { TrademarkAttributionComponent } from "./TrademarkAttribution.component";
import {
  ITrademarkAttributionDispatchProps,
  ITrademarkAttributionProps,
  ITrademarkAttributionStateProps,
  TrademarkAttributionProps,
} from "./TrademarkAttribution.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: ITrademarkAttributionProps
): ITrademarkAttributionStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: ITrademarkAttributionProps
): ITrademarkAttributionDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: ITrademarkAttributionStateProps,
  dispatch: ITrademarkAttributionDispatchProps,
  props: ITrademarkAttributionProps
): TrademarkAttributionProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const TrademarkAttribution = connect<
  ITrademarkAttributionStateProps,
  ITrademarkAttributionDispatchProps,
  ITrademarkAttributionProps,
  TrademarkAttributionProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TrademarkAttributionComponent);
