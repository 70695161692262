import { connect } from "react-redux";
import { FAQComponent } from "./FAQ.component";
import {
  FAQProps,
  IFAQDispatchProps,
  IFAQProps,
  IFAQStateProps,
} from "./FAQ.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: IFAQProps
): IFAQStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: IFAQProps
): IFAQDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: IFAQStateProps,
  dispatch: IFAQDispatchProps,
  props: IFAQProps
): FAQProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const FAQ = connect<
  IFAQStateProps,
  IFAQDispatchProps,
  IFAQProps,
  FAQProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FAQComponent);
