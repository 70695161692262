exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(//hello.myfonts.net/count/3c09d8);", ""]);
var urlEscape = require("../../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../../media/fonts/AachenPro-Medium/font.woff2"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../../media/fonts/AachenPro-Medium/font.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../../media/fonts/AachenPro-Bold/font.woff2"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../../../media/fonts/AachenPro-Bold/font.woff"));

// Module
exports.push([module.id, "/**\n * @license\n * MyFonts Webfont Build ID 3934680, 2020-08-12T08:15:00-0400\n * \n * The fonts listed in this notice are subject to the End User License\n * Agreement(s) entered into by the website owner. All other parties are \n * explicitly restricted from using the Licensed Webfonts(s).\n * \n * You may obtain a valid license at the URLs below.\n * \n * Webfont: AachenPro-Medium by ITC\n * URL: https://www.myfonts.com/fonts/itc/aachen/pro-medium/\n * \n * Webfont: AachenPro-Bold by ITC\n * URL: https://www.myfonts.com/fonts/itc/aachen/pro-bold/\n * \n * \n * Webfonts copyright: Copyright &amp;#x00A9; 2006 - 2012 Copyright of International Typeface Corporation. All rights reserved. Aachen is a trademark of International Typeface Corporation and may be registered in certain jurisdictions. This typeface is original artwork of Colin Bri\n * \n * © 2020 MyFonts Inc\n*/\n\n/* @import must be at top of file, otherwise CSS will not work */\n\n@font-face {\n  font-family: \"AachenPro-Medium\";\n  src: url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff2\"),\n    url(" + ___CSS_LOADER_URL___1___ + ") format(\"woff\");\n  font-display: swap;\n}\n\n@font-face {\n  font-family: \"AachenPro-Bold\";\n  src: url(" + ___CSS_LOADER_URL___2___ + ") format(\"woff2\"),\n    url(" + ___CSS_LOADER_URL___3___ + ") format(\"woff\");\n  font-display: swap;\n}\n", ""]);

