import { Layout } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { apiHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { brandName, youTubeKey, youTubePlaylistId } from 'theme/libs/config';
import logo from '../../media/images/mud_logo.png';
import { FourOhFour } from '../FourOhFour';
import { VideoList } from '../VideoList';
import { VideoListMobile } from '../VideoListMobile';
import './Videos.style.less';
import { VideosProps } from "./Videos.types";

export const VideosComponent = (props: VideosProps) => {
    const { Content, Sider } = Layout;

    const [displayFourOhFour, isLoading, data] = apiHandleSideEffects(
        `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${youTubePlaylistId}&key=${youTubeKey}&part=snippet&maxResults=50`
    );
    const [currentVideo, setCurrentVideo] = useState<IYouTubeVideo>();
    const [videos, setVideos] = useState<IYouTubeVideo[]>([]);

    useEffect(() => {
        setVideos(data?.items);
        setCurrentVideo(data?.items[0]);
    }, [data]);

    const handleVideoThumbnailClick = (i: number) => () => {
        setCurrentVideo(videos[i]);
    }

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading && <SpinnerCss />}
            { !isLoading && !!videos && !!currentVideo &&
                <Page
                    className="Videos"
                    description={`${brandName} - Videos`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Videos`}
                >
                    <Layout>
                        <Layout>
                            <Content>
                                <div className="video-wrapper">
                                    <iframe
                                        allowFullScreen
                                        className="video"
                                        frameBorder={0}
                                        src={`https://www.youtube.com/embed/${currentVideo.snippet.resourceId.videoId}?autoplay=1`}
                                        allow="autoplay"
                                    />
                                </div>
                                <div className="content-mobile">
                                    <VideoListMobile videos={videos} handleVideoThumbnailClick={handleVideoThumbnailClick} />
                                </div>
                                <div className="content container">
                                    <h1 className="content__title">{currentVideo.snippet.title}</h1>
                                    <p className="content__description">{currentVideo.snippet.description}</p>
                                    <div className="content__logo-wrapper">
                                        <a href="/">
                                            <img src={logo} className="content__logo" alt="Moose Utility Division Logo" />
                                        </a>
                                    </div>
                                </div>
                            </Content>
                        </Layout>
                        <Sider className="video-menu">
                            <VideoList videos={videos} handleVideoThumbnailClick={handleVideoThumbnailClick} />
                        </Sider>
                    </Layout>
                </Page>
            }
        </>
    )
}

interface IYouTubeVideo {
    etag: string;
    id: string;
    kind: string;
    snippet: IYouTubeVideoSnippet;
}

interface IYouTubeVideoSnippet {
    channelId: string;
    channelTitle: string;
    description: string;
    playlistId: string;
    position: number;
    publishedAt: string;
    resourceId: { kind: string, videoId: string };
    thumbnails: IYouTubeVideoSnippetThumbnails;
    title: string;
}

interface IYouTubeVideoSnippetThumbnails {
    default: IYouTubeVideoSnippetThumbnailsOptions;
    high: IYouTubeVideoSnippetThumbnailsOptions;
    maxres: IYouTubeVideoSnippetThumbnailsOptions;
    medium: IYouTubeVideoSnippetThumbnailsOptions;
    standard: IYouTubeVideoSnippetThumbnailsOptions;
}

interface IYouTubeVideoSnippetThumbnailsOptions {
    height: number;
    url: string;
    width: number;
}
