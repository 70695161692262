import { connect } from "react-redux";
import { LegalDisclaimerComponent } from "./LegalDisclaimer.component";
import {
  ILegalDisclaimerDispatchProps,
  ILegalDisclaimerProps,
  ILegalDisclaimerStateProps,
  LegalDisclaimerProps,
} from "./LegalDisclaimer.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: ILegalDisclaimerProps
): ILegalDisclaimerStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: ILegalDisclaimerProps
): ILegalDisclaimerDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: ILegalDisclaimerStateProps,
  dispatch: ILegalDisclaimerDispatchProps,
  props: ILegalDisclaimerProps
): LegalDisclaimerProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const LegalDisclaimer = connect<
  ILegalDisclaimerStateProps,
  ILegalDisclaimerDispatchProps,
  ILegalDisclaimerProps,
  LegalDisclaimerProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(LegalDisclaimerComponent);
