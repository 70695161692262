import { Layout } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SearchComponent as BaseComponent } from "lemans-brandsites-common/components/Search/Search.component";
import * as React from 'react';
import { brandName } from 'theme/libs/config';
import logoWithBlurb from "../../media/images/mud_logo_with_blurb.jpg";
import './Search.styles.less';
import { SearchProps } from "./Search.types";

// const Content = Layout;

export const SearchComponent = (props: SearchProps) =>
    <Page
        description={`${brandName} - Demand the brand`}
        robots={{ index: true, follow: true }}
        title={`${brandName} - Search`}
    >
        <Layout>
            <div className="logo-with-blurb-wrapper logo-with-blurb-wrapper">
                <a href="/">
                    <img className="logo-with-blurb" src={logoWithBlurb} alt={`${brandName} - Demand the Brand`} />
                </a>
            </div>
            <BaseComponent {...props} />
        </Layout>
    </Page>;
