import { connect } from "react-redux";
import { NavFindProductsComponent } from "./NavFindProducts.component";
import {
  INavFindProductsDispatchProps,
  INavFindProductsProps,
  INavFindProductsStateProps,
  NavFindProductsProps,
} from "./NavFindProducts.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: INavFindProductsProps
): INavFindProductsStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: INavFindProductsProps
): INavFindProductsDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: INavFindProductsStateProps,
  dispatch: INavFindProductsDispatchProps,
  props: INavFindProductsProps
): NavFindProductsProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const NavFindProducts = connect<
  INavFindProductsStateProps,
  INavFindProductsDispatchProps,
  INavFindProductsProps,
  NavFindProductsProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NavFindProductsComponent);
