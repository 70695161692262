import { PlayCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { getVideoUrl } from 'lemans-brandsites-common/libs/getVideoUrl';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { brandName } from 'theme/libs/config';
import logo from "../../../theme/media/images/mud_logo.png";
import { FourOhFour } from '../FourOhFour';
import './HomePage.style.less';
import { ISlide } from './HomePage.types';

export const HomePage = (): JSX.Element => {
    const [displayFourOhFour, isLoading, data] = pageRestHandleSideEffects('home');
    const [currentSlide, setCurrentSlide] = useState<ISlide | null>(null);
    const [isFading, setisFading] = useState(false);
    const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
    const [slides, setSlides] = useState<ISlide[]>([]);
    const [videoDomain, setVideoDomain] = useState<string>();
    const [videoId, setVideoId] = useState<string>();

    const slideLength = data?.acf.season_slider.length;
    const interval:number = data?.acf.transition_interval;

    let slideIndex = 0;

    useEffect(() => {
        if (currentSlide?.cta_video) {
            const [domain, id] = getVideoUrl(currentSlide.cta_video);
            setVideoDomain(domain);
            setVideoId(id);
        }
    }, [currentSlide]);

    useEffect(() => {
        setSlides(data?.acf.season_slider as ISlide[]);
        setCurrentSlide(data?.acf.season_slider[slideIndex]);
        slideIndex++;
    }, [data]);

    useEffect(() => {
        let timer: number | undefined;
        if (!isVideoModalVisible && !!interval) {
            timer = window.setInterval(() => {
                setCurrentSlide(data?.acf.season_slider[slideIndex]);
                slideIndex < slideLength - 1
                    ? slideIndex++
                    : slideIndex = 0;
            }, interval * 1000);
        }
        return () => window.clearInterval(timer);
    }, [data, isVideoModalVisible]);

    const showVideoModal = () => {
        setIsVideoModalVisible(true);
    };

    const handleVideoModalOk = () => {
        setIsVideoModalVisible(false);
    };

    const handleVideoModalCancel = () => {
        setIsVideoModalVisible(false);
    };

    const goToSlide = (index: number) => () => {
        setisFading(true);
        setTimeout(() => {
            setisFading(false);
        }, 700);
        setCurrentSlide(data?.acf.season_slider[index]);
    }

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading && <SpinnerCss />}
            { !isLoading && !!currentSlide &&
                <Page
                    className="HomePage"
                    description={`${brandName} - Home`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Home`}
                >
                    <div id="img-preload" style={{ display: "none" }}>
                        {slides.map(slide => <img key={slide.title} src={`${apiConfig.host}${slide.image}`} />)}
                    </div>
                    <div className="background">
                        <div
                            className={`slider ${isFading ? "fadeIn" : ""}`}
                            style={{ backgroundImage: `linear-gradient(to left, ${data?.acf.background_overlay}, ${data?.acf.background_overlay}), url(${apiConfig.host}${currentSlide.image})` }}
                        >
                            <div className="slider__content">
                                <div className="slider__slide">
                                    <div className="slider__logo">
                                        <Link to="/">
                                            <img
                                                alt="Moose Utility Division Logo"
                                                src={logo}
                                            />
                                        </Link>
                                    </div>
                                    <h1 className="slider__title">{currentSlide.title}</h1>
                                    <div className="slider__text">{currentSlide.description}</div>
                                    <div className="slider__cta">
                                        <Link className="slider__cta-link" to={currentSlide.link_url}>
                                            <div className="slider__cta-text">{currentSlide.link_text}</div>
                                        </Link>
                                    </div>
                                    <div className="slider__demand">
                                        <Link className="slider__demand-link" to="/">
                                            <span className="slider__demand-link-text" onClick={showVideoModal}>
                                                <PlayCircleOutlined />
                                                Demand The Brand
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="timeline-wrapper">
                            <div className="timeline">
                                <h3 className="timeline__header">Seasons</h3>
                                <ul className="timeline__list">
                                    {slides.map((slide: ISlide, i: number) =>
                                        <li
                                            className={`${slide.title === currentSlide.title
                                                ? 'timeline__item timeline__item--active'
                                                : 'timeline__item'}`}
                                            key={slide.title}
                                            onClick={goToSlide(i)}
                                        >
                                            <span className="timeline__item-title">{slide.title}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Modal
                        className="video-modal"
                        destroyOnClose={true}
                        footer={null}
                        onCancel={handleVideoModalCancel}
                        onOk={handleVideoModalOk}
                        visible={isVideoModalVisible}
                        width={'80vw'}
                    >
                        <div className="video-wrapper">
                            <iframe
                                allowFullScreen
                                className="video"
                                frameBorder={0}
                                src={`https://www.${videoDomain}.com/embed/${videoId}?autoplay=1`}
                                allow="autoplay"
                            />
                        </div>
                    </Modal>
                </Page>
            }
        </>
    );
}
