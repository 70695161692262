import { mapDispatchToProps, mapStateToProps, mergeProps} from "lemans-brandsites-common/components/Search/Search.container";
import { connect } from 'react-redux';
import { SearchComponent } from './Search.component';
import { ISearchDispatchProps, ISearchProps, ISearchStateProps, SearchProps } from "./Search.types";

// Uncomment the following lines to customize the Redux behavior of this component

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
// export const mapStateToProps = (state:any, props:ISearchProps):ISearchStateProps => ({
// 
// });

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
// export const mapDispatchToProps = (dispatch:any, props:ISearchProps):ISearchDispatchProps => ({

// });

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
// export const mergeProps = (state:ISearchStateProps, dispatch:ISearchDispatchProps, props:ISearchProps):SearchProps => ({
//     ...state,
//     ...dispatch,
//     ...props,
// });

export const Search = connect<ISearchStateProps, ISearchDispatchProps, ISearchProps, SearchProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(SearchComponent);