import { Breadcrumb, Col, Layout, Row, Tabs } from 'antd';
import { Page } from 'lemans-brandsites-common/components//Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { imageUrl } from 'lemans-brandsites-common/libs/imageUrl';
import { IFeaturesResponseData, IMediaResponseData, IProductGroupResponseData, IProductResponseData } from 'lemans-brandsites-common/libs/Product/product.types';
import * as React from "react";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { brandName, missingImageUrl } from 'theme/libs/config';
import logo from '../../media/images/mud_logo.png';
import { Chart } from '../Chart/Chart.component';
import { FourOhFour } from '../FourOhFour';
import { ProductFitment } from '../ProductFitment';
import { ProductVideo } from '../ProductVideo';
import { getProductData } from './ProductDetails.helper';
import './ProductDetails.styles.less';
import { ProductDetailsProps } from "./ProductDetails.types";

export const ProductDetailsComponent = (props: ProductDetailsProps) => {
    const { Content } = Layout;
    const { TabPane } = Tabs;

    const [currentImage, setCurrentImage] = useState<IMediaResponseData>();
    const [displayFourOhFour, setDisplayFourOhFour] = useState(false);
    const [images, setImages] = useState<IMediaResponseData[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState<IProductResponseData>();
    const [video, setVideo] = useState<IMediaResponseData>();
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbData[]>();

    useEffect(() => {
        getProductData(props, setIsLoading, setDisplayFourOhFour, setProduct);
    }, [props.productId, props.productGroupId]);

    useEffect(() => {
        if (product) {
            setBreadcrumbs(product?.breadcrumb);
            const imageMedia =
                (product as IProductResponseData).media
                    .filter((media: IMediaResponseData) => media.mediaTypeDescription === "image");

            const primaryImageIndex = imageMedia.findIndex((media: IMediaResponseData) => media.mediaUrl === product.mediaUrl);
            const primaryImage = imageMedia[primaryImageIndex] ? imageMedia[primaryImageIndex] : imageMedia[0];
            setCurrentImage(primaryImage);

            // Re-order primary image to first position
            if (primaryImageIndex > -1) {
                imageMedia.splice(primaryImageIndex, 1);    // Remove
                imageMedia.splice(0, 0, primaryImage);      // Add
            }
            setImages(imageMedia.length >= 5 ? imageMedia.slice(0, 5) : imageMedia);

            const videoMedia =
                (product as IProductResponseData).media
                    .find((media: IMediaResponseData) => media.mediaTypeDescription === "video");
            setVideo(videoMedia);
        }
    }, [product]);

    const handlePreviewImageClick = (image: IMediaResponseData) => (event: any) => {
        setCurrentImage(image);
    }

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading && <SpinnerCss />}
            { !isLoading && !!product &&
                <Page
                    className="ProductDetails"
                    description={`${brandName} - ${product.productName}`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - ${product.productName}`}
                >
                    <Layout>
                        <Content>
                            <div className="container-lg">
                                <Row className="breadcrumbs">
                                    <Col>
                                        <Breadcrumb separator=">">
                                            {breadcrumbs?.map((breadcrumb: IBreadcrumbData) =>
                                                <Breadcrumb.Item key={breadcrumb.categoryId}>
                                                    <Link to={`/category/${breadcrumb.categoryId}`} dangerouslySetInnerHTML={{ __html: breadcrumb.categoryName }} />
                                                </Breadcrumb.Item>
                                            )}
                                            <Breadcrumb.Item>
                                                <strong dangerouslySetInnerHTML={{ __html: product.entityName }} />
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <a href="/">
                                            <img className="logo" src={logo} alt="Moose Utility Division Logo" />
                                        </a>
                                    </Col>
                                </Row>
                                <Row className="product">
                                    <Col lg={24} xl={12}>
                                        <Row>
                                            <Col>
                                                <h1 className="product__name" dangerouslySetInnerHTML={{ __html: product.productName }} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span className="product__price">
                                                    {product.retailPriceDisplayRange}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                {product.features.map((feature: IFeaturesResponseData) =>
                                                    <p key={`feature-${feature.sequence}`}>
                                                        {feature.feature}
                                                    </p>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={24} xl={12}>
                                        {currentImage &&
                                            <Row>
                                                <Col span={24}>
                                                    <div className={images && images.length === 1 ? "image__primary-wrapper image__primary-wrapper--mb" : "image__primary-wrapper"}>
                                                        <img
                                                            alt={product.productName}
                                                            className="image__primary"
                                                            src={imageUrl(currentImage as IMediaResponseData, { x: 600, y: 600, t: 'image/jpeg' }, missingImageUrl)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        {images && images.length > 1 &&
                                            <Row>
                                                <Col span={24}>
                                                    <div className="image__preview-container">
                                                        {images?.map((image: IMediaResponseData, index: number) =>
                                                            <img
                                                                alt={product.productName}
                                                                className={image === currentImage ? "image__preview image__preview--active" : "image__preview"}
                                                                key={`${image.entityId}-${index}`}
                                                                onClick={handlePreviewImageClick(image)}
                                                                style={{ width: `calc(100% / ${images.length})` }}
                                                                src={imageUrl(image, { x: 300, y: 300, t: 'image/jpeg' }, missingImageUrl)}
                                                            />
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Tabs defaultActiveKey="1">
                                            {!!product.productGroups && product.productGroups[0].chart[0] &&
                                                <TabPane tab="Part Numbers" key="1">
                                                    <div className="container-sm">
                                                        <Chart title="Part Numbers" chart={product.productGroups[0].chart[0] || []} partNumberLabel='Part Numbers' product={product}/>
                                                        {/* <ProductPartNumbers parts={product.parts} /> */}
                                                    </div>
                                                </TabPane>
                                            }
                                            {!!product.productGroups.some((group: IProductGroupResponseData) => group.fitments) &&
                                                <TabPane tab="Fitment" key="2">
                                                    <ProductFitment productGroups={product.productGroups} />
                                                </TabPane>
                                            }
                                            {!!video &&
                                                <TabPane tab="Video" key="3">
                                                    <ProductVideo video={video} />
                                                </TabPane>
                                            }
                                        </Tabs>
                                    </Col>
                                </Row>
                            </div>
                        </Content>
                    </Layout>
                </Page>
            }
        </>
    );
}

interface IBreadcrumbData {
    categoryName: string;
    categoryLevel: number;
    categoryId: number;
}
