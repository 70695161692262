import { connect } from "react-redux";
import { NavSocialComponent } from "./NavSocial.component";
import {
  INavSocialDispatchProps,
  INavSocialProps,
  INavSocialStateProps,
  NavSocialProps,
} from "./NavSocial.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: INavSocialProps
): INavSocialStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: INavSocialProps
): INavSocialDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: INavSocialStateProps,
  dispatch: INavSocialDispatchProps,
  props: INavSocialProps
): NavSocialProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const NavSocial = connect<
  INavSocialStateProps,
  INavSocialDispatchProps,
  INavSocialProps,
  NavSocialProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NavSocialComponent);
