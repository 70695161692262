import * as React from "react";
import { Link } from 'react-router-dom';
import './SelectedFilters.styles.less';
import { SelectedFiltersProps } from "./SelectedFilters.types";

export const SelectedFiltersComponent = (props: SelectedFiltersProps) => {
    const removeURLParameter = (valueToRemove: string) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.forEach((value, key) => {
            if (valueToRemove === value) {
                queryParams.delete(key);
            }
        });
        return decodeURIComponent(window.location.pathname + '?' + queryParams.toString());
    }

    const onSearch = async () => {
        if (props.onSearch) {
            props.onSearch();
        }
    }

    return (
        <>
            <h3>Selected Filters</h3>
            {props.facetHeader && props.facetHeader.facets
                .filter((res: any) => res.type !== 'ci')
                .map((selectedFacet: any) =>
                    selectedFacet.values && selectedFacet.values.length > 0 &&
                    selectedFacet.values.map((selectedFacetValue: any, selectedFacetValueIndex: number) =>
                        <div className="filter" key={`search-filter-${selectedFacetValueIndex}`}>
                            <span className="filter__name">{selectedFacet.name}: <span dangerouslySetInnerHTML={{ __html: selectedFacetValue.name }} /></span>
                            <Link className="filter__remove" to={removeURLParameter(selectedFacetValue.id)}>
                                <span id={selectedFacet.type} onClick={onSearch}>remove</span>
                            </Link>
                        </div>
                    )
                )}
        </>
    );
}
