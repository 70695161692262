import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { useState } from 'react';
import { brandName } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import { StaticTemplate } from '../StaticTemplate';
import './FAQ.style.less';
import { FAQProps } from "./FAQ.types";

export const FAQComponent = (props: FAQProps) => {
    const [displayFourOhFour, isLoading, data] = pageRestHandleSideEffects('faq');
    const [faqs, setFaqs] = useState<[]>([]);
    const [title, setTitle] = useState<string>();
    React.useEffect(() => {
        setFaqs(data?.acf.faqs);
        setTitle(data?.title.rendered);
    }, [data]);

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading && <SpinnerCss />}
            { !isLoading && !!title && !!faqs &&
                <Page
                    className="FAQ"
                    description={`${brandName} - FAQ`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - FAQ`}
                >
                    <StaticTemplate hasHeader={true} imageUrl={data?.acf.side_banner}>
                        <h2 dangerouslySetInnerHTML={{ __html: title }} />
                        {faqs.map((faq: any, i: number) => {
                            return (
                                <ul className="faq-list" key={i}>
                                    <li className="faq-list__item" dangerouslySetInnerHTML={{ __html: faq.question }} />
                                    <li className="faq-list__item" dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                </ul>
                            );
                        })}
                    </StaticTemplate>
                </Page>
            }
        </>
    )
};
