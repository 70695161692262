import { getVideoUrl } from 'lemans-brandsites-common/libs/getVideoUrl';
import * as React from 'react';
import './ProductVideo.styles.less';
import { ProductVideoProps } from "./ProductVideo.types";

export const ProductVideoComponent = (props: ProductVideoProps) => {
    const [domain, id] = getVideoUrl(props.video.mediaUrl);

    return (
        <div className="ProductVideo">
            <div className="video">
                <iframe
                    allowFullScreen
                    frameBorder={0}
                    src={`https://www.${domain}.com/embed/${id}?autoplay=1`}
                    allow="autoplay"
                />
            </div>
        </div>
    )
}
