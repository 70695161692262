import { FilePdfTwoTone } from '@ant-design/icons';
import { Col, Layout, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { imageUrl } from 'lemans-brandsites-common/libs/imageUrl';
import { getPart } from 'lemans-brandsites-common/libs/Part/part';
import { IAttributeData, IPartNumber } from 'lemans-brandsites-common/libs/Part/part.types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { brandName, missingImageUrl } from 'theme/libs/config';
import logo from '../../media/images/mud_logo.png';
import { FourOhFour } from '../FourOhFour';
import './PartNumber.styles.less';
import { PartNumberProps } from "./PartNumber.types";

export const PartNumberComponent = (props: PartNumberProps) => {
    const { Content } = Layout;

    const [displayFourOhFour, setDisplayFourOhFour] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [part, setPart] = useState<IPartNumber>();

    const getData = async () => {
        setIsLoading(true);
        try {
            const partData: IPartNumber = await getPart(props.partNumber as number);
            setPart(partData);

            if (!!part) {
                setDisplayFourOhFour(true);
            }
        }
        catch (err: any) {
            console.log('err', err);
            setDisplayFourOhFour(true);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, [props.partNumber]);

    const instructionMedia = part?.media.filter((med:any) =>  med.viewingAngle.toLowerCase() === "manual" && med.extension === "pdf");
    const docSrc = instructionMedia && instructionMedia[0] !== undefined ? {
            extension: !!instructionMedia[0].extension ? instructionMedia[0].extension : '',
            mediaUrl: !!instructionMedia[0].mediaUrl ? instructionMedia[0].mediaUrl : '',
            prefixUrl: !!instructionMedia[0].prefixUrl ? instructionMedia[0].prefixUrl : '',
        } : 
        undefined;

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading &&
                <SpinnerCss />
            }
            { !isLoading && !!part &&
                <Page
                    className="PartNumber"
                    description={`${brandName} - Part Number ${props.partNumber}`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Part Number ${props.partNumber}`}
                >
                    <Layout>
                        <Content>
                            <div className="container">
                                <Row>
                                    <Col>
                                        <a href="/">
                                            <img className="logo" src={logo} alt="Moose Utility Division Logo" />
                                        </a>
                                    </Col>
                                </Row>
                                <Row className="part">
                                    <Col lg={24} xl={12}>
                                        <Row>
                                            <Col>
                                                <h1 className="part__name">
                                                    {part.entityName}<br />
                                                    ({part.punctuatedPartNumber})
                                                </h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span className="part__price">
                                                    {part.retailPriceDisplay}
                                                </span>
                                            </Col>
                                        </Row>
                                        {part.attributes.map((attr: IAttributeData) =>
                                            <Row key={attr.attributeNameId} className="attr">
                                                <Col span={12} className="attr__name">
                                                    {attr.attributeName}:
                                                </Col>
                                                <Col span={12} className="attr__value">
                                                    {attr.attributeValue}
                                                </Col>
                                            </Row>
                                        )}
                                        <br />
                                        {!!docSrc && docSrc !== undefined && 
                                            <div className='instruction'>
                                                <a
                                                    href={!!docSrc ? imageUrl(docSrc) : ''}
                                                    target={'_blank'}
                                                    title={'Click again if download did not automatically start.'}
                                                >
                                                    INSTALLATION INSTRUCTIONS <FilePdfTwoTone twoToneColor="#ff0000" />
                                                </a>
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={24} xl={12}>
                                        <div className="part__image-wrapper">
                                            <img
                                                alt={part.entityName}
                                                className="part__image"
                                                src={imageUrl(part.media[0], { x: 600, y: 600, t: 'image/jpeg' }, missingImageUrl)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Content>
                    </Layout>
                </Page>
            }
        </>
    )
}
