import { connect } from 'react-redux';
import { ProductsComponent } from './Products.component';
import {IProductsDispatchProps, IProductsProps, IProductsStateProps, ProductsProps} from "./Products.types";
// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IProductsProps):IProductsStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IProductsProps):IProductsDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IProductsStateProps, dispatch:IProductsDispatchProps, props:IProductsProps):ProductsProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const Products = connect<IProductsStateProps, IProductsDispatchProps, IProductsProps, ProductsProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ProductsComponent);