import { connect } from "react-redux";
import { NavProductsComponent } from "./NavProducts.component";
import {
  INavProductsDispatchProps,
  INavProductsProps,
  INavProductsStateProps,
  NavProductsProps,
} from "./NavProducts.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: INavProductsProps
): INavProductsStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: INavProductsProps
): INavProductsDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: INavProductsStateProps,
  dispatch: INavProductsDispatchProps,
  props: INavProductsProps
): NavProductsProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const NavProducts = connect<
  INavProductsStateProps,
  INavProductsDispatchProps,
  INavProductsProps,
  NavProductsProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NavProductsComponent);
