import { ComponentDemo } from "lemans-brandsites-common/components/ComponentDemo";
import { asPage } from "lemans-brandsites-common/libs/asPage";
import { Blog } from "theme/components/Blog";
import { IBlogProps } from "theme/components/Blog/Blog.types";
import { Contact } from "theme/components/Contact";
import { DealerLocator } from "theme/components/DealerLocator";
import { FAQ } from "theme/components/FAQ";
import { FourOhFour } from "theme/components/FourOhFour";
import { HomePage } from "theme/components/HomePage";
import { InstructionSearch } from "theme/components/InstructionSearch";
import { LegalDisclaimer } from "theme/components/LegalDisclaimer";
import { OnlineDealers } from "theme/components/OnlineDealers";
import { OurStory } from "theme/components/OurStory";
import { PartNumber } from "theme/components/PartNumber";
import { PrivacyPolicy } from "theme/components/PrivacyPolicy";
import { ProductDetails } from "theme/components/ProductDetails";
import { IProductDetailsProps } from "theme/components/ProductDetails/ProductDetails.types";
import { ProductListing } from "theme/components/ProductListing";
import { IProductListingProps } from "theme/components/ProductListing/ProductListing.types";
import { Products } from "theme/components/Products";
import { Search } from "theme/components/Search";
import { TermsAndConditions } from "theme/components/TermsAndConditions";
import { TrademarkAttribution } from "theme/components/TrademarkAttribution";
import { Videos } from "theme/components/Videos";

export const routes = [
  { route: "/", exact: true, component: HomePage },
  { route: "/blog", exact: true, component: Blog },
  {
    component: asPage<IBlogProps>(Blog),
    exact: true,
    route: "/blog/:slug",
  },
  { route: "/contact", exact: true, component: Contact },
  { route: "/dealer-locator", exact: true, component: DealerLocator },
  { route: "/demo", exact: true, component: ComponentDemo },
  { route: "/faq", exact: true, component: FAQ },
  {
    component: InstructionSearch,
    exact: true,
    route: "/installation-instructions",
  },
  { route: "/legal-disclaimer", exact: true, component: LegalDisclaimer },
  { route: "/online-dealers", exact: true, component: OnlineDealers },
  { route: "/our-story", exact: true, component: OurStory },
  { route: "/products", exact: true, component: Products },
  { route: "/privacy-policy", exact: true, component: PrivacyPolicy },
  { route: "/search", component: Search },
  {
    component: TermsAndConditions,
    exact: true,
    route: "/terms-and-conditions",
  },
  {
    component: TrademarkAttribution,
    exact: true,
    route: "/trademark-attribution",
  },
  { route: "/videos", exact: true, component: Videos },
  {
    component: asPage<IProductListingProps>(ProductListing),
    exact: true,
    route: "/category/:categoryId",
  },
  {
    component: asPage<IProductDetailsProps>(PartNumber),
    exact: true,
    route: "/parts/:partNumber",
  },
  {
    component: asPage<IProductDetailsProps>(ProductDetails),
    exact: true,
    route: "/products/:productId",
  },
  {
    component: asPage<IProductDetailsProps>(ProductDetails),
    exact: true,
    route: "/products/:productId/productGroup/:productGroupId",
  },
  { component: FourOhFour },
];
