import { connect } from "react-redux";
import { BlogRecentPostsComponent } from "./BlogRecentPosts.component";
import {
  BlogRecentPostsProps,
  IBlogRecentPostsDispatchProps,
  IBlogRecentPostsProps,
  IBlogRecentPostsStateProps,
} from "./BlogRecentPosts.types";
// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: IBlogRecentPostsProps
): IBlogRecentPostsStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: IBlogRecentPostsProps
): IBlogRecentPostsDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: IBlogRecentPostsStateProps,
  dispatch: IBlogRecentPostsDispatchProps,
  props: IBlogRecentPostsProps
): BlogRecentPostsProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const BlogRecentPosts = connect<
  IBlogRecentPostsStateProps,
  IBlogRecentPostsDispatchProps,
  IBlogRecentPostsProps,
  BlogRecentPostsProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(BlogRecentPostsComponent);
