import { Col, Row } from 'antd';
import { imageUrl } from 'lemans-brandsites-common/libs/imageUrl';
import { IProductResponseData } from 'lemans-brandsites-common/libs/Product/product.types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { missingImageUrl } from 'theme/libs/config';
import './Products.styles.less';
import { ProductsProps } from "./Products.types";

export const ProductsComponent = (props: ProductsProps) =>
    <div className="Products">
        <Row>
            {props.products
                ? props.products.map((product: IProductResponseData) => {
                    return (
                        <Col className="product" key={product.productId} xl={8} lg={12} md={24}>
                            <div className="product__item">
                                <Link className="product__link" to={`/products/${product.productId}`}>
                                    <div className="product__image-wrapper">
                                        <img
                                            alt={product.productName}
                                            className="product__image"
                                            src={imageUrl(product, { x: 400, y: 400, t: 'image/jpeg' }, missingImageUrl)}
                                        />
                                    </div>
                                    <h3 className="product__name">{product.productName}</h3>
                                    <div className="product__price">
                                        {product.retailPriceDisplayRange ? product.retailPriceDisplayRange : <>&nbsp;</>}
                                    </div>
                                </Link>
                            </div>
                        </Col>
                    )
                })
                : null
            }
        </Row>
    </div>
