import { connect  } from "react-redux";
import { SelectedFiltersComponent  } from "./SelectedFilters.component";
import { ISelectedFiltersDispatchProps, ISelectedFiltersProps, ISelectedFiltersStateProps, SelectedFiltersProps } from "./SelectedFilters.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:ISelectedFiltersProps):ISelectedFiltersStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:ISelectedFiltersProps):ISelectedFiltersDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:ISelectedFiltersStateProps, dispatch:ISelectedFiltersDispatchProps, props:ISelectedFiltersProps):SelectedFiltersProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const SelectedFilters = connect<ISelectedFiltersStateProps, ISelectedFiltersDispatchProps, ISelectedFiltersProps, SelectedFiltersProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(SelectedFiltersComponent);