import { connect } from "react-redux";
import { NavCatalogComponent } from "./NavCatalog.component";
import {
  INavCatalogDispatchProps,
  INavCatalogProps,
  INavCatalogStateProps,
  NavCatalogProps,
} from "./NavCatalog.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: INavCatalogProps
): INavCatalogStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: INavCatalogProps
): INavCatalogDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: INavCatalogStateProps,
  dispatch: INavCatalogDispatchProps,
  props: INavCatalogProps
): NavCatalogProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const NavCatalog = connect<
  INavCatalogStateProps,
  INavCatalogDispatchProps,
  INavCatalogProps,
  NavCatalogProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NavCatalogComponent);
