// Place for defining stuff which needs to be passed to shared/common components
import { default as brandLogo } from "../media/images/mud_loader_straight.png";

export const gAnalyticsId = "UA-35840604-1";
export const gaMeasurementId = "G-1G2QTSVLG9";
export const localSiteUrl = `http://local.mooseutilities.com`;
export const stageSiteUrl = `https://stage.mooseutilities.com`;
export const readySiteUrl = `https://ready.mooseutilities.com`;
export const prodSiteUrl = `https://www.mooseutilities.com`;
export const stageHostUrl = `stage.mooseutilities.com`;
export const prodHostUrl = `www.mooseutilities.com`;
export const readyHostUrl = `ready.mooseutilities.com`;
export const navTemplate = "nav2020";
export const spinnerLogo = brandLogo;
export const catalogTemplate = "catalog";
export const categoryTemplate = "category";
export const partTemplate = "part";
export const prodTemplate = "product";
export const prodGrpTemplate = "ProductGroup2020";
export const siteKey = "6LeyXt8ZAAAAAAtxW356zZZIbv81TXiZx_rVUO1l";
export const prodMapsKey = "AIzaSyDAyKGBbP_8gP7DfHRVpFgxT9R4XFqzlI8";
export const devMapsKey = "AIzaSyCKLPoKMTbDZwBOEQuzayYjEUUUZ2hDpFg";
export const youTubeChannelId = "UC6oeR5fDqQ7XVkuWirNuCVg";
export const youTubeKey = "AIzaSyDrZcA-BFSZeDo6FgSDUzu_1fbj4W056Gk";
export const youTubePlaylistId = "UU6oeR5fDqQ7XVkuWirNuCVg";
export const brandName = "Moose Utility Division";

export const missingImageUrl = "static/sites/mooseutilities/images/mud_no_image.jpg";