import { connect  } from "react-redux";
import { InstructionSearchComponent  } from "./InstructionSearch.component";
import { IInstructionSearchDispatchProps, IInstructionSearchProps, IInstructionSearchStateProps, InstructionSearchProps } from "./InstructionSearch.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IInstructionSearchProps):IInstructionSearchStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IInstructionSearchProps):IInstructionSearchDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IInstructionSearchStateProps, dispatch:IInstructionSearchDispatchProps, props:IInstructionSearchProps):InstructionSearchProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const InstructionSearch = connect<IInstructionSearchStateProps, IInstructionSearchDispatchProps, IInstructionSearchProps, InstructionSearchProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(InstructionSearchComponent);