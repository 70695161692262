import { connect } from "react-redux";
import { TermsAndConditionsComponent } from "./TermsAndConditions.component";
import {
  ITermsAndConditionsDispatchProps,
  ITermsAndConditionsProps,
  ITermsAndConditionsStateProps,
  TermsAndConditionsProps,
} from "./TermsAndConditions.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: ITermsAndConditionsProps
): ITermsAndConditionsStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: ITermsAndConditionsProps
): ITermsAndConditionsDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: ITermsAndConditionsStateProps,
  dispatch: ITermsAndConditionsDispatchProps,
  props: ITermsAndConditionsProps
): TermsAndConditionsProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const TermsAndConditions = connect<
  ITermsAndConditionsStateProps,
  ITermsAndConditionsDispatchProps,
  ITermsAndConditionsProps,
  TermsAndConditionsProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TermsAndConditionsComponent);
