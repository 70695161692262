import { connect } from "react-redux";
import { SpinnerCssComponent } from "./SpinnerCss.component";
import {
  ISpinnerCssDispatchProps,
  ISpinnerCssProps,
  ISpinnerCssStateProps,
  SpinnerCssProps,
} from "./SpinnerCss.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: ISpinnerCssProps
): ISpinnerCssStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: ISpinnerCssProps
): ISpinnerCssDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: ISpinnerCssStateProps,
  dispatch: ISpinnerCssDispatchProps,
  props: ISpinnerCssProps
): SpinnerCssProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const SpinnerCss = connect<
  ISpinnerCssStateProps,
  ISpinnerCssDispatchProps,
  ISpinnerCssProps,
  SpinnerCssProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SpinnerCssComponent);
