import { connect } from "react-redux";
import { NavAboutComponent } from "./NavAbout.component";
import {
  INavAboutDispatchProps,
  INavAboutProps,
  INavAboutStateProps,
  NavAboutProps,
} from "./NavAbout.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: INavAboutProps
): INavAboutStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: INavAboutProps
): INavAboutDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: INavAboutStateProps,
  dispatch: INavAboutDispatchProps,
  props: INavAboutProps
): NavAboutProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const NavAbout = connect<
  INavAboutStateProps,
  INavAboutDispatchProps,
  INavAboutProps,
  NavAboutProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NavAboutComponent);
