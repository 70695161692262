import { DownCircleOutlined, UpCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { imageUrl } from 'lemans-brandsites-common/libs/imageUrl';
import { IProductResponseData } from 'lemans-brandsites-common/libs/Product/product.types';
import * as React from "react";
import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { brandName, missingImageUrl } from 'theme/libs/config';
import { category } from '../../../lemans-brandsites-common/libs/Category/category';
import { ICategoryResponseData } from '../../../lemans-brandsites-common/libs/Category/category.types';
import logoWithBlurb from "../../media/images/mud_logo_with_blurb.jpg";
import { Products } from '../Products';
import './ProductListing.styles.less';
import { ProductListingProps } from "./ProductListing.types";

export const ProductListingComponent = (props: ProductListingProps) => {
    const [data, setData] = useState<ICategoryResponseData | any | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    const downRef = React.useRef<HTMLInputElement>(null);
    const upRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        getData();
    }, [props.categoryId]);

    const doSetShowScrollToTop = (val: boolean) => () => {
        setShowScrollToTop(val);
    }

    const getData = async () => {
        setIsLoading(true);
        try {
            const categoryData = await category(props.categoryId);
            setData(categoryData);
        }
        catch (err: any) {
            console.log('err', err);
        }
        finally {
            setIsLoading(false);
        }
    }

    const scrollDown = () => {
        if (downRef.current) {
            downRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    const scrollToTop = () => {
        if (upRef.current) {
            upRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <>
            { isLoading &&
                <SpinnerCss />
            }
            { !isLoading && !!data &&
                <Page
                    className="ProductListing"
                    description={`${brandName} - Product Listing`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Product Listing`}
                >
                    <Waypoint
                        onEnter={doSetShowScrollToTop(false)}
                        onLeave={doSetShowScrollToTop(true)}
                    />
                    <UpCircleFilled
                        className={`scroll-to-top ${showScrollToTop ? 'scroll-to-top--active' : ''}`}
                        onClick={scrollToTop}
                    />
                    <div
                        className="categories-nav-wrapper"
                        ref={upRef}
                    >
                        <div
                            className="categories-nav"
                            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(${imageUrl(data, undefined, missingImageUrl)})` }}
                        >
                            <div className="categories-nav__content">
                                <h1 className="categories-nav__title">{data.categoryName}</h1>
                                {!!data.categories &&
                                    <ul className="categories-nav__list">
                                        {data.categories
                                            ? data.categories.map((cat: ICategoryResponseData) =>
                                                <li className="categories-nav__item" key={cat.categoryId}>
                                                    <a href={`#${cat.categoryId}`}>
                                                        {cat.categoryName}
                                                    </a>
                                                </li>
                                            )
                                            : null}
                                    </ul>
                                }
                            </div>
                        </div>
                        <div className="scroll-down">
                            <span className="scroll-down__content">
                                <DownCircleOutlined onClick={scrollDown} /><br />
                                Scroll down
                            </span>
                        </div>
                        <div ref={downRef} />
                        <div className="container">
                            <div className="logo-with-blurb-wrapper logo-with-blurb-wrapper--center">
                                <a href="/">
                                    <img className="logo-with-blurb" src={logoWithBlurb} alt={`${brandName}- Demand the Brand`} />
                                </a>
                            </div>
                            <Products products={data.products} />
                        </div>
                    </div>
                    {data.categories ?
                        <div className="container-lg">
                            {data.categories.map((cat: ICategoryResponseData) =>
                                <div className="category" key={cat.categoryId} id={cat.categoryId.toString()}>
                                    <Row>
                                        <Col>
                                            <h2 className="category__title">{cat.categoryName}</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Products products={cat.products as IProductResponseData[]} />
                                        </Col>
                                    </Row>
                                </div>)}
                        </div>
                        : null
                    }
                </Page>
            }
        </>
    );
}
