import * as React from 'react';
import { catalog } from './Catalog/catalog';
import { ICatalogResponseData } from './Catalog/catalog.types';

export const useCatalog = ():[ICatalogResponseData | undefined, boolean] => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [cat, setCat] = React.useState<ICatalogResponseData | undefined>(undefined);

    React.useEffect(() => {
        setIsLoading(true);
        catalog()
        .then((d) => {
            setIsLoading(false);
            setCat(d);
        });
    }, []);

    return [cat, isLoading];
}
