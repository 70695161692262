import {toggle} from 'basic-reducers';

const createToggle = (name:string) => ({
    hide: () => toggle.hide(name),
    isActive: (state:any, def:boolean):boolean => toggle.isVisible(() => state, name, def),
    show: () => toggle.show(name),
    toggle: (def:boolean) => toggle.toggle(name, def),
});

export const nav = createToggle("nav");
