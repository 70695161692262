import { connect } from 'react-redux';
import { ProductFitmentComponent } from './ProductFitment.component';
import {IProductFitmentDispatchProps, IProductFitmentProps, IProductFitmentStateProps, ProductFitmentProps} from "./ProductFitment.types";
// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IProductFitmentProps):IProductFitmentStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IProductFitmentProps):IProductFitmentDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IProductFitmentStateProps, dispatch:IProductFitmentDispatchProps, props:IProductFitmentProps):ProductFitmentProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const ProductFitment = connect<IProductFitmentStateProps, IProductFitmentDispatchProps, IProductFitmentProps, ProductFitmentProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ProductFitmentComponent);