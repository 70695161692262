import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { brandName } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import { StaticTemplate } from '../StaticTemplate';
import './Contact.style.less';
import { ContactProps } from "./Contact.types";
import { ContactForm } from './ContactForm/ContactForm.container';

export const ContactComponent = (props: ContactProps) => {
    const [displayFourOhFour, isLoading, data] = pageRestHandleSideEffects('contact');
    const [title, setTitle] = useState<string>();

    useEffect(() => {
        setTitle(data?.title.rendered)
    }, [data]);

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading && <SpinnerCss />}
            { !isLoading && !!data && !!title &&
                <Page
                    className="Contact"
                    description={`${brandName} - Contact Us`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Contact Us`}
                >
                    <StaticTemplate hasHeader={true} imageUrl={data?.acf.side_banner}>
                        <ContactForm domain={6} formKey='mud_2020' title={title} />
                    </StaticTemplate>
                </Page>
            }
        </>
    )
};
