import { connect } from 'react-redux';
import { PartNumberComponent } from './PartNumber.component';
import {IPartNumberDispatchProps, IPartNumberProps, IPartNumberStateProps, PartNumberProps} from "./PartNumber.types";
// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IPartNumberProps):IPartNumberStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IPartNumberProps):IPartNumberDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IPartNumberStateProps, dispatch:IPartNumberDispatchProps, props:IPartNumberProps):PartNumberProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const PartNumber = connect<IPartNumberStateProps, IPartNumberDispatchProps, IPartNumberProps, PartNumberProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PartNumberComponent);