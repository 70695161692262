import { FilePdfOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { page } from 'lemans-brandsites-common/libs/GetPageFromWP';
import * as React from 'react';
import './NavCatalog.style.less';
import { NavCatalogProps } from "./NavCatalog.types";

export const NavCatalogComponent = (props: NavCatalogProps) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [thumb, setThumb] = React.useState("");
    const [pdfLink, setPdfLink] = React.useState("");
    const [size, setSize] = React.useState("");
    const [flipper, setFlipper] = React.useState("");

    React.useEffect(() => {
        setIsLoading(true);
        page('catalog').then(data => {
            setIsLoading(false);
            if(!!data && !!data.acf) {
                setThumb(data.acf.catalog_thumb.url);
                setPdfLink(data.acf.catalog_pdf_link);
                setSize(data.acf.pdf_download_size);
                setFlipper(data.acf.catalog_flipper_link);
            }
        })
    }, []);

    return <Spin spinning={isLoading}>
        <div className="NavCatalog">
            <div className="nav-section__header">Catalog</div>
            {!isLoading && <Row>
                <Col xs={{ order: 2, span: 24 }} sm={{ order: 1, span: 12 }}>
                    <div className="catalog-image-wrapper">
                        <img className="catalog-image" src={`${apiConfig.host}${thumb}`} />
                    </div>
                </Col>
                <Col xs={{ order: 1, span: 24 }} sm={{ order: 2, span: 12 }} className="nav-section__links">
                    <a href={pdfLink} target="blank">
                        <FilePdfOutlined />
                        <div>
                            Download PDF ({size} Mb)
                        </div>
                    </a>
                    <a href={flipper} target="blank">View digital version</a>
                </Col>
            </Row>}
        </div>
    </Spin>;
}
