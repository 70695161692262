import { product } from "lemans-brandsites-common/libs/Product";
import { IMediaResponseData } from "lemans-brandsites-common/libs/Product/product.types";
import { ProductDetailsProps } from "./ProductDetails.types";

export async function getProductData(
  props: ProductDetailsProps,
  setIsLoading: any,
  setDisplayFourOhFour: any,
  setProducts: any
) {
  try {
    setIsLoading(true);
    const data = await product(props.productId);
    setProducts(data);
  } catch (err: any) {
    console.warn("err", err);
    setDisplayFourOhFour(true);
  } finally {
    setIsLoading(false);
  }
}

export function getVideoFromMedia(medium: IMediaResponseData[], setVideo: any) {
  return medium.find(
    (media: IMediaResponseData) => media.mediaTypeDescription === "video"
  );
}
