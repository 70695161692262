import { connect } from "react-redux";
import { OurStoryComponent } from "./OurStory.component";
import {
  IOurStoryDispatchProps,
  IOurStoryProps,
  IOurStoryStateProps,
  OurStoryProps,
} from "./OurStory.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: IOurStoryProps
): IOurStoryStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: IOurStoryProps
): IOurStoryDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: IOurStoryStateProps,
  dispatch: IOurStoryDispatchProps,
  props: IOurStoryProps
): OurStoryProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const OurStory = connect<
  IOurStoryStateProps,
  IOurStoryDispatchProps,
  IOurStoryProps,
  OurStoryProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(OurStoryComponent);
