import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { nav } from 'theme/libs/nav-redux';
import { NavMenuComponent } from "./NavMenu.component";
import { INavMenuDispatchProps, INavMenuProps, INavMenuStateProps, NavMenuProps } from "./NavMenu.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: INavMenuProps
): INavMenuStateProps => ({
  isNavOpen: nav.isActive(state, false),
});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: INavMenuProps
): INavMenuDispatchProps => ({
  toggleIsNavOpen: () => {dispatch(nav.toggle(false));},
});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: INavMenuStateProps,
  dispatch: INavMenuDispatchProps,
  props: INavMenuProps
): NavMenuProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const NavMenu = withRouter(connect<
  INavMenuStateProps,
  INavMenuDispatchProps,
  INavMenuProps,
  NavMenuProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NavMenuComponent));
