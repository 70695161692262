import { mapDispatchToProps, mapStateToProps, mergeProps} from "lemans-brandsites-common/components/SearchResults/SearchResults.container";
import { connect } from 'react-redux';
import { SearchResultsComponent } from './SearchResults.component';
import { ISearchResultsDispatchProps, ISearchResultsProps, ISearchResultsStateProps, SearchResultsProps } from "./SearchResults.types";

// Uncomment the following lines to customize the Redux behavior of this component

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
// export const mapStateToProps = (state:any, props:ISearchResultsProps):ISearchResultsStateProps => ({
// 
// });

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
// export const mapDispatchToProps = (dispatch:any, props:ISearchResultsProps):ISearchResultsDispatchProps => ({

// });

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
// export const mergeProps = (state:ISearchResultsStateProps, dispatch:ISearchResultsDispatchProps, props:ISearchResultsProps):SearchResultsProps => ({
//     ...state,
//     ...dispatch,
//     ...props,
// });

export const SearchResults = connect<ISearchResultsStateProps, ISearchResultsDispatchProps, ISearchResultsProps, SearchResultsProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(SearchResultsComponent);