import { connect } from 'react-redux';
import { VideoListMobileComponent } from './VideoListMobile.component';
import {IVideoListMobileDispatchProps, IVideoListMobileProps, IVideoListMobileStateProps, VideoListMobileProps} from "./VideoListMobile.types";
// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IVideoListMobileProps):IVideoListMobileStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IVideoListMobileProps):IVideoListMobileDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IVideoListMobileStateProps, dispatch:IVideoListMobileDispatchProps, props:IVideoListMobileProps):VideoListMobileProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const VideoListMobile = connect<IVideoListMobileStateProps, IVideoListMobileDispatchProps, IVideoListMobileProps, VideoListMobileProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(VideoListMobileComponent);