import * as React from 'react';
import './NavAbout.style.less';
import { NavAboutProps } from "./NavAbout.types";

export const NavAboutComponent = (props: NavAboutProps) => {
    const aboutLists = [
        { label: 'Terms and Conditions', href: 'terms-and-conditions' }, { label: 'FAQ', href: 'faq' },
        { label: 'Contact', href: 'contact' }, { label: 'Our Story', href: 'our-story' },
        { label: 'Blog', href: 'blog' }, { label: 'Trademark Attribution', href: 'trademark-attribution' }
    ];

    return (
        <div className="NavAbout">
            <div className="nav-section__header">About</div>
            <ul className="nav-section__list nav-section__list--no-border nav-section__list--no-uppercase">
                {aboutLists.map(list =>
                    <li className="nav-section__item" key={list.href}><a href={`/${list.href}`}>{list.label}</a></li>
                )}
            </ul>
        </div>
    )
};
