import { connect } from "react-redux";
import { VideosComponent } from "./Videos.component";
import {
  IVideosDispatchProps,
  IVideosProps,
  IVideosStateProps,
  VideosProps,
} from "./Videos.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: IVideosProps
): IVideosStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: IVideosProps
): IVideosDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: IVideosStateProps,
  dispatch: IVideosDispatchProps,
  props: IVideosProps
): VideosProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const Videos = connect<
  IVideosStateProps,
  IVideosDispatchProps,
  IVideosProps,
  VideosProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VideosComponent);
