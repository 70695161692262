import { connect } from "react-redux";
import { nav } from 'theme/libs/nav-redux';
import { NavComponent } from "./Nav.component";
import { INavDispatchProps, INavProps, INavStateProps, NavProps } from "./Nav.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:INavProps):INavStateProps => ({
    isNavOpen: nav.isActive(state, false),
});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:INavProps):INavDispatchProps => ({
    toggleIsNavOpen: () => {dispatch(nav.toggle(false));},
});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:INavStateProps, dispatch:INavDispatchProps, props:INavProps):NavProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const Nav = connect<INavStateProps, INavDispatchProps, INavProps, NavProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(NavComponent);