import * as React from 'react';
import './NavFooter.style.less';
import { NavFooterProps } from "./NavFooter.types";

export const NavFooterComponent = (props: NavFooterProps) =>
    <div className="NavFooter">
        <ul className="nav-section__list nav-section__list--no-border">
            <li className="nav-section__item"><a href="/privacy-policy">Privacy Policy</a></li>
            <li className="nav-section__item"><a href="/legal-disclaimer">Legal Disclaimer</a></li>
            <li className="nav-section__item"><a href="http://cpsc.lemansnet.com" target="blank">Conformity Certificates</a></li>
        </ul>
        <div className="copyright">
            <ul>
                <li>
                    © {new Date().getFullYear()} LeMans Corporation. All Rights Reserved
                </li>
            </ul>
        </div>
    </div>;
