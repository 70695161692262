import { Layout } from 'antd';
import { ReduxContainer } from 'lemans-brandsites-common/components/ReduxContainer';
import * as React from 'react';
import { Nav } from 'theme/components/Nav/Nav';
import { gAnalyticsId } from 'theme/libs/config';
import '../../../theme/libs/Styles/App/App.style.less';
import { MainPageLayout } from '../MainPageLayout';
import "./App.style.less";

export const App = (props: any) => {
    return (
        <ReduxContainer analyticsId={gAnalyticsId}>
            <div className="App">
                <Layout>
                    <MainPageLayout />
                    <Layout.Sider className="sider" width={100}>
                        <Nav />
                    </Layout.Sider>
                </Layout>
            </div>
        </ReduxContainer>
    )
}