import { Drawer } from 'antd';
import * as React from "react";
import { NavAbout } from '../NavAbout';
import { NavCatalog } from '../NavCatalog';
import { NavFindProducts } from '../NavFindProducts';
import { NavFooter } from '../NavFooter';
import { NavMenu } from '../NavMenu';
import { NavProducts } from '../NavProducts';
import '../NavShared.styles.less';
import { NavSocial } from '../NavSocial';
import './Nav.styles.less';
import { NavProps } from "./Nav.types";

export const NavComponent = (props: NavProps) =>
    <div className={`Nav`}>
        <NavMenu />
        <NavSocial />
        <Drawer
            className="nav__drawer"
            closable={false}
            onClose={props.toggleIsNavOpen}
            visible={props.isNavOpen}
        >
            <div className="nav-section">
                <NavProducts />
            </div>
            <div className="nav-section">
                <NavFindProducts />
            </div>
            <div className="nav-section nav-section--no-border">
                <NavCatalog />
            </div>
            <div className="nav-section nav-section--no-border">
                <NavAbout />
            </div>
            <div className="nav-section nav-section--no-border">
                <NavFooter />
            </div>
        </Drawer>
    </div>;
