import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { nav } from 'theme/libs/nav-redux';
import { MainPageLayoutComponent } from './MainPageLayout.component';
import { IMainPageLayoutDispatchProps, IMainPageLayoutProps, IMainPageLayoutStateProps, MainPageLayoutProps } from "./MainPageLayout.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IMainPageLayoutProps):IMainPageLayoutStateProps => ({
    isNavOpen: nav.isActive(state, true),
});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IMainPageLayoutProps):IMainPageLayoutDispatchProps => ({
    closeNav: () => {dispatch(nav.hide());},
});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IMainPageLayoutStateProps, dispatch:IMainPageLayoutDispatchProps, props:IMainPageLayoutProps):MainPageLayoutProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const MainPageLayout = withRouter(connect<IMainPageLayoutStateProps, IMainPageLayoutDispatchProps, IMainPageLayoutProps, MainPageLayoutProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(MainPageLayoutComponent));