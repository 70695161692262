import { Button, Col, Input, Row } from 'antd';
import { getState } from 'lemans-brandsites-common';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { api } from 'lemans-brandsites-common/libs/api';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import { solrParameters } from 'lemans-brandsites-common/libs/SolrParameters';
import * as React from "react";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { brandName, partTemplate } from 'theme/libs/config';
import { StaticTemplate } from '../StaticTemplate';
import { FitmentSearchInputGroup } from './FitmentSearch/FitmentSearchInputGroup';
import './InstructionSearch.styles.less';
import { InstructionSearchProps } from "./InstructionSearch.types";
import { InstructionSearchResults } from './InstructionSearchResults';

export const InstructionSearchComponent = (props: InstructionSearchProps) => {
    const [, , sideImageData] = pageRestHandleSideEffects('installation-instructions');

    const queryParams = new URLSearchParams(window.location.search);

    // const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSearchResults, setIsLoadingSearchResults] = useState(true);
    const [errorState, setErrorState] = useState(false);
    const state = getState({
        docType: 'part',
        partNumber: queryParams.has('partNumber') ? queryParams.get('partNumber')!.toString() : '',
        queryParamToggle: false, // Re-render if the query params in the url changes        
        searchResults: [] as any,
    });

    const searchLink = state.partNumber.value
        ? `?partNumber=${state.partNumber.value}`
        : '?partNumber=';

    // Re-render search on either when the state.queryParamToggle.value is set 
    // or when the page url is updated (2nd option is for making the narrow filters work) 
    useEffect(() => {
        getSearchResults();
    }, [state.queryParamToggle.value, window.location.search]);

    // Modify state once the url has changed (when the user removes one/more selected filters from the results)
    useEffect(() => {
        if (state.partNumber.value !== (queryParams.has('partNumber') ? queryParams.get('partNumber')!.toString() : '')) {
            state.partNumber.set(queryParams.has('partNumber') ? queryParams.get('partNumber')!.toString() : '')
        }
    }, [window.location.search]);

    const getSearchResults = async () => {
        const configParams = await solrParameters();
        const urlObj = {
            ci: configParams.catalogInstanceId,
            docType: state.docType.value,
            rows: 500,
        }

        queryParams.forEach((value, key) => {
            urlObj[key] = value;
        })

        if (queryParams.get('partNumber')) {
            setIsLoadingSearchResults(true);
            setErrorState(true);
            try {
                const data = await api.get('lemans/api/feed-info/masterFeed')
                    .query(
                        urlObj
                    )
                    .query({
                        locale: 'en',
                        templateName: partTemplate
                    })
                    .query({ vehicleTypeId: 18004 })
                    .query({ vehicleTypeId: 18008 })
                    .query({ vehicleTypeId: 18009 });

                const parts = data.body.root[0].domain[0].semantic.find((s: any) => s.semanticName === "body").parts;
                if (parts && parts.length > 0) {
                    const filteredPartMedia =
                        parts[0].media.filter((filteredMedia: any) =>
                            filteredMedia.viewingAngle.toLowerCase() === 'manual' && filteredMedia.extension === 'pdf'
                        );

                    if (filteredPartMedia.length > 0) {
                        state.searchResults.set(parts);
                    } else {
                        state.searchResults.set([]);
                    }
                } else {
                    state.searchResults.set([]);
                }
            }
            catch (err) {
                console.log(err)
            }
            finally {
                setIsLoadingSearchResults(false);
            }
        } else if (queryParams.get('mk') || queryParams.get('yr') || queryParams.get('md')) {
            setIsLoadingSearchResults(true);

            try {
                const data = await api.get(`lemans/api/solr-info/search`)
                    .query(
                        urlObj
                    )
                    .query(
                        { hasInstallationInstructions: true }
                    );

                state.searchResults.set(data.body);
            }
            catch (err) {
                console.log(err);
            }
            finally {
                setIsLoadingSearchResults(false);
            }
        } else {
            state.searchResults.set([]);
            setErrorState(false);
            setIsLoadingSearchResults(false);
        }
    }

    const onPartNoSearchSubmit = (e: any) => {
        const partNumberInput: any = document.getElementById('searchPartNumber');
        const partNumberInputVal = partNumberInput.value;

        if (partNumberInputVal.length > 0) {
            state.partNumber.set(partNumberInputVal.replace("-", "").toString());
        } else {
            state.partNumber.set("");
        }
    }

    const onSearch = () => {
        state.queryParamToggle.set(!state.queryParamToggle.value);
    }

    const partInputSubmit = () => {
        const partNumberInput: any = document.getElementById('searchPartNumber');
        const partNumberInputVal = partNumberInput.value;
        history.pushState(null, '', `?partNumber=${partNumberInputVal}`);
        onSearch();
    }

    return (
        <>
            { isLoadingSearchResults &&
                <SpinnerCss />
            }
            {!isLoadingSearchResults &&
                <Page
                    className="InstallationInstructions"
                    description={`${brandName} - Installation Instructions`}
                    robots={{ index: true, follow: true }}
                    title={`${brandName} - Installation Instructions`}
                >
                    <StaticTemplate hasHeader={true} imageUrl={sideImageData?.acf.side_banner}>
                        {state.searchResults.value &&
                            <>
                                <h2>Installation Instructions</h2>
                                <div className="part-number-search-container">
                                    <Row>
                                        <Col span={24}>
                                            <h3>Search By Part Number</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="part-number-search" span={24}>
                                            <Input
                                                allowClear={true}
                                                autoComplete="off"
                                                className="part-number-search__input"
                                                id="searchPartNumber"
                                                name="partNumber"
                                                onChange={onPartNoSearchSubmit}
                                                onPressEnter={partInputSubmit}
                                                placeholder="Enter Part Number"
                                                type="text"
                                                value={state.partNumber.value}
                                            />
                                            <Link to={searchLink}>
                                                <Button
                                                    className="part-number-search__button styled-button-wrapper--button"
                                                    onClick={onSearch}
                                                >
                                                    Search
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                                <FitmentSearchInputGroup
                                    docType="part"
                                    mode="installation-instructions"
                                    onSearch={onSearch}
                                />
                                <InstructionSearchResults
                                    errorState={errorState}
                                    isLoading={isLoadingSearchResults}
                                    onSearch={onSearch}
                                    searchResults={state.searchResults.value}
                                />
                            </>
                        }
                    </StaticTemplate>
                </Page>
            }
        </>
    );
}
