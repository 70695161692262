import { Drawer } from 'antd';
import * as React from "react";
import logo from "../../../media/images/mud_logo.png";
import { NavAbout } from '../NavAbout';
import { NavCatalog } from '../NavCatalog';
import { NavFindProducts } from '../NavFindProducts';
import { NavFooter } from '../NavFooter';
import { NavMenu } from '../NavMenu';
import { NavProducts } from '../NavProducts';
import '../NavShared.styles.less';
import { NavSocial } from '../NavSocial';
import './NavMobile.styles.less';
import { NavMobileProps } from "./NavMobile.types";

// tslint:disable-next-line:variable-name
export const NavMobileComponent = (props: NavMobileProps) =>
    <div className={`NavMobile`}>
        <div className="top-bar">
            <NavMenu />
        </div>
        <div className="bottom-bar">
            <span className="nav-logo">
                <a href="/">
                    <img
                        alt="Moose Utility Division Logo"
                        className="nav-logo__image"
                        src={logo}
                    />
                </a>
            </span>
            <span className="social-wrapper">
                <NavSocial />
            </span>
        </div>
        <Drawer
            className="nav-mobile__drawer"
            closable={false}
            onClose={props.toggleIsNavOpen}
            visible={props.isNavOpen}
        >
            <div className="nav-section">
                <NavProducts />
            </div>
            <div className="nav-section">
                <NavFindProducts />
            </div>
            <div className="nav-section nav-section--no-border">
                <NavCatalog />
            </div>
            <div className="nav-section nav-section--no-border">
                <NavAbout />
            </div>
            <div className="nav-section nav-section--no-border">
                <NavFooter />
            </div>
        </Drawer>
    </div>;
    