import { Button, Spin } from 'antd';
import Select, { SelectValue } from 'antd/lib/select';
import { api } from 'lemans-brandsites-common/libs/api';
import { solrParameters } from 'lemans-brandsites-common/libs/SolrParameters';
import * as React from 'react';
import { Link } from 'react-router-dom';
import './NavFindProducts.style.less';
import { NavFindProductsProps } from "./NavFindProducts.types";

const { Option } = Select;

export interface IFacet {
    count: number;
    id: string;
    name: string;
}

export const NavFindProductsComponent = (props: NavFindProductsProps) => {
    const [years, setYears] = React.useState<IFacet[]>([]);
    const [makes, setMakes] = React.useState<IFacet[]>([]);
    const [models, setModels] = React.useState<IFacet[]>([]);
    const [loading, setLoading] = React.useState(false);

    const [yr, setSelectedYear] = React.useState<string | undefined>();
    const [mk, setSelectedMake] = React.useState<string | undefined>();
    const [md, setSelectedModel] = React.useState<string | undefined>();
    const updateOption = (f: (v: string) => void) => (value: SelectValue) => f(value as string);

    const reset = () => {
        setSelectedYear(undefined);
        setSelectedMake(undefined);
        setSelectedModel(undefined);
    }

    const refresh = async () => {
        setLoading(true);
        const configParams = await solrParameters();
        const params = {
            ci: configParams.catalogInstanceId,
            docType: "productGroup",
            rows: 1,
        }
        api.get(`lemans/api/solr-info/search`)
            .query({ ...params, yr, md, mk})
            .then((response: any) => {
                setLoading(false);
                setYears(response.body.facets.filter((f: any) => f.name === "Fitment Year")[0]?.values || []);
                setMakes(response.body.facets.filter((f: any) => f.name === "Fitment Make")[0]?.values || []);
                setModels(response.body.facets.filter((f: any) => f.name === "Fitment Model")[0]?.values || []);
            });
    }

    React.useEffect(() => { refresh(); }, [yr, mk, md]);

    const values = {yr, mk, md};
    const searchUrl = "/search?" + Object.keys(values).map((key) => values[key] ? `${key}=${values[key]}` : undefined).filter(a => a).join("&");
    

    return <Spin spinning={loading}>
        <div className="NavFindProducts">
            <div className="nav-section__header">Find Products For Your Vehicle</div>
            <Select className="nav-section__select-wrapper" defaultValue="Select Year" value={yr} onChange={updateOption(setSelectedYear)}>
                {years.map(y => <Option key={y.id} value={y.id}>{y.name}</Option>)}
            </Select>
            <Select className="nav-section__select-wrapper" defaultValue="Select Make" value={mk} onChange={updateOption(setSelectedMake)}>
                {makes.map(y => <Option key={y.id} value={y.id}>{y.name}</Option>)}
            </Select>
            <Select disabled={!yr && !mk} className="nav-section__select-wrapper" defaultValue="Select Model" value={md} onChange={updateOption(setSelectedModel)}>
                {models.map(y => <Option key={y.id} value={y.id}>{y.name}</Option>)}
            </Select>
            <div className="nav-section__search">
                <Button className="styled-button-wrapper--button">
                    <Link to={searchUrl} onClick={reset}>
                        Search
                    </Link>
                </Button>
            </div>
        </div>
    </Spin>
};
