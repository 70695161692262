import { ICountryList } from "lemans-brandsites-common/libs/DealerLocatorApi/libs/Country/country.types";

// What gets passed into the component from the parent as attributes
export interface IDealerLocatorInputGroupProps {
  apiConfig: any;
  storeResults?: (results: ICountryList[]) => void;
  onOnlineDealerClick?: () => void;
  onSearch?: (inputState: DealerLocatorInputParams) => void;
  className?: IDealerLocatorClassNameProps | string;
}

// What gets returned from the first connect function (mapStateToProps)
export interface IDealerLocatorInputGroupStateProps {}

// What gets returned from the second connect function (mapDispatchToProps)
export interface IDealerLocatorInputGroupDispatchProps {}

// What gets added in the third connect function (mergeProps)
export interface IDealerLocatorInputGroupMergeProps {}

// The props that finally get passed to the component
export type DealerLocatorInputGroupProps = IDealerLocatorInputGroupStateProps &
  IDealerLocatorInputGroupDispatchProps &
  IDealerLocatorInputGroupMergeProps &
  IDealerLocatorInputGroupProps;

interface IDealerLocatorClassNameProps {
  button?: IDealerLocatorButtonClassNameProps | string;
  dropdown?: IDealerLocatorSelectClassNameProps | string;
  input?: IDealerLocatorInputClassNameProps | string;
  radio?: IDealerLocatorRadioClassNameProps | string;
  select?: IDealerLocatorSelectClassNameProps | string;
}

interface IDealerLocatorSelectClassNameProps {
  country?: string;
  range?: string;
  state?: string;
}

interface IDealerLocatorRadioClassNameProps {
  searchType?: string;
}

interface IDealerLocatorInputClassNameProps {
  city?: string;
  zip?: string;
}

interface IDealerLocatorButtonClassNameProps {
  onlineDealers?: string;
  search?: string;
}

export enum inputElements {
  button = "button",
  dropdown = "dropdown",
  input = "input",
  radio = "radio",
  select = "select",
}

export type DealerLocatorInputParams =
  | IDealerLocatorInternationalParameters
  | IDealerLocatorZipParameters
  | IDealerLocatorCityParameters;

interface IDealerLocatorInternationalParameters {
  country?: string;
}

interface IDealerLocatorZipParameters {
  country?: string;
  distance: number;
  zipCode: string;
}

interface IDealerLocatorCityParameters {
  country?: string;
  distance: number;
  city: string;
  state: string;
}
