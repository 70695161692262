import { FacebookOutlined, InstagramOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons';
import * as React from 'react';
import './NavSocial.style.less';
import { NavSocialProps } from "./NavSocial.types";

export const NavSocialComponent = (props: NavSocialProps) =>
    <div className="NavSocial">
        <div className="social">
            <ul className="social__list">
                <li className="social__item social__item--facebook">
                    <a href="https://www.facebook.com/MooseUtilities" target="_new"><FacebookOutlined /></a>
                </li>
                <li className="social__item social__item--twitter">
                    <a href="https://twitter.com/mooseutilities" target="_new"><TwitterOutlined /></a>
                </li>
                <li className="social__item social__item--instagram">
                    <a href="https://www.instagram.com/mooseutilities" target="_new"><InstagramOutlined /></a>
                </li>
                <li className="social__item social__item--youtube">
                    <a href="https://www.youtube.com/channel/UC6oeR5fDqQ7XVkuWirNuCVg" target="_new"><YoutubeOutlined /></a>
                </li>
            </ul>
        </div>
    </div>;
