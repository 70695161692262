import { connect } from "react-redux";
import { StaticTemplateComponent } from "./StaticTemplate.component";
import {
  IStaticTemplateDispatchProps,
  IStaticTemplateProps,
  IStaticTemplateStateProps,
  StaticTemplateProps,
} from "./StaticTemplate.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: IStaticTemplateProps
): IStaticTemplateStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: IStaticTemplateProps
): IStaticTemplateDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: IStaticTemplateStateProps,
  dispatch: IStaticTemplateDispatchProps,
  props: IStaticTemplateProps
): StaticTemplateProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const StaticTemplate = connect<
  IStaticTemplateStateProps,
  IStaticTemplateDispatchProps,
  IStaticTemplateProps,
  StaticTemplateProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(StaticTemplateComponent);
