import { CloseOutlined, EditOutlined, HomeOutlined, MailOutlined, MenuOutlined, PlayCircleOutlined, SearchOutlined, ToolOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import globe from '../../../media/images/globe_icon.png';
import './NavMenu.style.less';
import { NavMenuProps } from "./NavMenu.types";

export const NavMenuComponent = (props: NavMenuProps & RouteComponentProps<any>) => {
    const [searchTerm, setSearchTerm] = React.useState("");
    const [isSearchVisible, setIsSearchVisible] = React.useState(false);

    const searchContainerRef = useRef(null);

    const search = () => {
        props.history.push(`/search?q=${searchTerm}`);
        setIsSearchVisible(false);
        setSearchTerm('');
    }

    const toggleIsSearchVisible = () => {
        setIsSearchVisible(v => !v);
    }

    const updateSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => { setSearchTerm(e.currentTarget.value); }

    const useOutsideAlerter = (ref: any) => {
        useEffect(() => {
            function handleClickOutside(e: any) {
                console.log('event', e);
                if ((ref.current && !ref.current.contains(e.target))) {
                    setIsSearchVisible(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(searchContainerRef);

    return (
        <div className="NavMenu">
            <ul className="menu">
                <li className="nav-collapse">
                    <a onClick={props.toggleIsNavOpen}>
                        {!props.isNavOpen ? <MenuOutlined /> : <CloseOutlined />}
                        <div className="nav-option-label">Products</div>
                    </a>
                </li>
                <li>
                    <Link to="/">
                        <HomeOutlined title="Home" />
                    </Link>
                </li>
                <li className='li-search'>
                    {isSearchVisible &&
                        <div
                            className="menu-search-container"
                            ref={searchContainerRef}
                        >
                            <Input
                                autoFocus={true}
                                onChange={updateSearchTerm}
                                onPressEnter={search}
                                placeholder="Enter search term"
                                prefix={<SearchOutlined />}
                                size="large"
                                value={searchTerm}
                            />
                        </div>
                    }
                    <a href="#" onClick={toggleIsSearchVisible}>
                        <SearchOutlined title="Search" />
                    </a>
                </li>
                <li>
                    <Link to="/dealer-locator">
                        <img className="icon-globe" src={globe} title="Dealer Locator" />
                    </Link>
                </li>
                <li>
                    <Link to="/videos">
                        <PlayCircleOutlined title="Videos" />
                    </Link>
                </li>
                <li>
                    <Link to="/blog">
                        <EditOutlined title="Blog" />
                    </Link>
                </li>
                <li>
                    <Link to="/contact">
                        <MailOutlined title="Contact" />
                    </Link>
                </li>
                <li>
                    <Link to="/installation-instructions">
                        <ToolOutlined title="Installation Instructions" />
                    </Link>
                </li>
            </ul>
        </div>
    )
}
