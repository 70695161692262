import { Col, Row, Tabs } from 'antd';
import { IFitmentPartsData, IFitmentResponseData, IProductGroupResponseData } from 'lemans-brandsites-common/libs/Product/product.types';
import * as _ from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import './ProductFitment.styles.less';
import { ProductFitmentProps } from "./ProductFitment.types";

export const ProductFitmentComponent = (props: ProductFitmentProps) => {
    const { TabPane } = Tabs;

    const fitmentGroups = props.productGroups.map((productGroup: IProductGroupResponseData) =>
        _.groupBy(productGroup.fitments, 'makeName'));

    return (
        <div className="ProductFitment">
            <Tabs className="fitment" defaultActiveKey="1">
                {fitmentGroups.map((group: any) =>
                    Object.entries(group).map(([key, values]: any) =>
                        <TabPane tab={key} key={key}>
                            <div className="container-sm">
                                <Row className="fitment__headers">
                                    <Col span={2}>
                                        Year
                                    </Col>
                                    <Col span={4}>
                                        Model
                                    </Col>
                                    <Col span={4}>
                                        Part(s)
                                    </Col>
                                </Row>
                                {values.map((value: IFitmentResponseData, index: number) =>
                                    <Row key={`${value.modelId}-${index}`}>
                                        <Col span={2}>
                                            {value.year}
                                        </Col>
                                        <Col span={4}>
                                            {value.modelName}
                                        </Col>
                                        <Col span={4}>
                                            <ul className="fitment__parts">
                                                {value.parts.map((part: IFitmentPartsData) =>
                                                    <li key={part.punctuatedPartNumber}>
                                                        <Link to={`/parts/${part.partNumber}`}>
                                                            {part.punctuatedPartNumber}
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </TabPane>
                    )
                )}
            </Tabs>
        </div>
    )
}
