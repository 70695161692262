import { connect } from "react-redux";
import { NavFooterComponent } from "./NavFooter.component";
import {
  INavFooterDispatchProps,
  INavFooterProps,
  INavFooterStateProps,
  NavFooterProps,
} from "./NavFooter.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: INavFooterProps
): INavFooterStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: INavFooterProps
): INavFooterDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: INavFooterStateProps,
  dispatch: INavFooterDispatchProps,
  props: INavFooterProps
): NavFooterProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const NavFooter = connect<
  INavFooterStateProps,
  INavFooterDispatchProps,
  INavFooterProps,
  NavFooterProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NavFooterComponent);
