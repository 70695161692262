import { api } from '../api';

let params:ILfrParams;
export const feedParameters = async ():Promise<ILfrParams> => {
    // Memoize this so it's only called once
    if(!params) {
        const res = await api.get(`lemans/lfr/settings`);
        params = res.body as ILfrParams;
    }
    return params;
}

interface ILfrParams {
    domainId: number;
    catalogInstanceId: number;
}