import { partTemplate } from "theme/libs/config";
import { api } from "../api";
import { feedParameters } from "../FeedParameters";
import { IPartNumber } from "./part.types";

export const getPart = async (partNumber: number) => {
  const feedParams = await feedParameters();
  const res = await getCategory(partNumber, feedParams);
  const body = res.body.root[0].domain[0].semantic.filter(
    (semData: any) => semData.semanticName === "body"
  )[0];
  let data = {} as IPartNumber;

  if (body.parts) {
    data = body.parts[0];
  }

  return data;
};

const getCategory = (partNumber: number, feedParams: any): any =>
  api.get("lemans/api/feed-info/masterFeed").query(feedParams).query({
    locale: "en",
    partNumber,
    templateName: partTemplate,
  });
