import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { brandName } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import { StaticTemplate } from '../StaticTemplate';
import './OurStory.style.less';
import { OurStoryProps } from "./OurStory.types";

export const OurStoryComponent = (props: OurStoryProps) => {
    const [displayFourOhFour, isLoading, data] = pageRestHandleSideEffects('our-story');
    const [content, setContent] = useState<string>();
    const [title, setTitle] = useState<string>();

    useEffect(() => {
        setContent(data?.content.rendered);
        setTitle(data?.title.rendered);
    }, [data]);

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading && <SpinnerCss />}
            { !isLoading && !!title && !!content &&
                <Page
                    className="OurStory"
                    description={`${brandName} - Our Story`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Our Story`}
                >
                    <StaticTemplate hasHeader={true} imageUrl={data?.acf.side_banner}>
                        <h2 dangerouslySetInnerHTML={{ __html: title }} />
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </StaticTemplate>
                </Page>
            }
        </>
    )
};
