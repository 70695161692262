import { Spin } from 'antd';
import * as React from "react";
import { spinnerLogo } from "theme/libs/config";
import './Spinner.styles.less';
import { SpinnerProps } from "./Spinner.types";

export const SpinnerComponent = (props: SpinnerProps) => {
    return (
        < Spin
            spinning={props.isLoading === undefined ? true : props.isLoading}
            delay={50}
            className={props.children ? 'spinner-wrapper-children--spin' : 'spinner-wrapper--spin'}
            indicator={<img src={spinnerLogo} className={'spinner--img'} />}
            children={props.children}
        />
    )
}
