import { Col, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { onlineDealer } from 'lemans-brandsites-common/libs/DealerLocatorApi';
// import { apiConfig } from 'lemans-brandsites-common/libs/config';
// import { onlineDealer } from 'lemans-brandsites-common/libs/DealerLocatorApi';
import { IDealerData } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/OnlineDealer/onlineDealer.types';
import * as React from "react";
import { OnlineDealerItem } from './OnlineDealerItem';
import './OnlineDealers.styles.less';
import { OnlineDealersProps } from "./OnlineDealers.types";

export const OnlineDealersComponent = (props: OnlineDealersProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [onlineDealerData, setOnlineDealerData] = React.useState<IDealerData[] | undefined>(undefined);

    const getData = async () => {
        setIsLoading(true);
        try {
            const [dealerRes] = await Promise.all([onlineDealer(apiConfig)]);
            setOnlineDealerData(dealerRes);
        }
        finally {
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <>
            { isLoading &&
                <Spinner />
            }
            { !isLoading &&
                <Page
                    description={'Moose Utilities online dealers'}
                    robots={{ follow: true, index: true }}
                    title={'Moose Utilities - Online Dealers'}
                >
                    <Row
                        align={'bottom'}
                        justify="space-around"
                    >
                        <Col xs={18}>
                            <div className={'online-dealers-wrapper--div'} >
                                <h1>Online Dealers</h1>
                                <Row gutter={16}>
                                    {Array.isArray(onlineDealerData) &&
                                        onlineDealerData.filter(dealer => dealer.dealerWebSiteUrl).map(dealer =>
                                            <Col key={dealer.dealerName} xl={8} lg={12} md={24}>
                                                <OnlineDealerItem data={dealer} />
                                            </Col>
                                        )}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Page>
            }
        </>
    );
}