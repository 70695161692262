import { Spin } from 'antd';
import { ICategoryResponseData } from 'lemans-brandsites-common/libs/Category/category.types';
import { useCatalog } from 'lemans-brandsites-common/libs/useCatalog';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './NavProducts.style.less';
import { NavProductsProps } from "./NavProducts.types";

export const NavProductsComponent = (props: NavProductsProps) => {
    const [data, isLoading] = useCatalog();
    const [categories, setCategories] = useState<ICategoryResponseData[]>();

    useEffect(() => {
        const visibleCategories =
            data?.categories
                .filter((category: ICategoryResponseData) => !category.tag.toLowerCase().includes("hidden"));
        setCategories(visibleCategories);
    }, [data]);

    console.log('cat', data);

    return <Spin spinning={isLoading}>
        <div className="NavProducts">
            <div className="nav-section__header">Product</div>
            {!!categories &&
                <ul className="nav-section__list">
                    {categories.map((subCat: ICategoryResponseData) =>
                        <li className="nav-section__item" key={subCat.categoryId}>
                            <Link to={`/category/${subCat.categoryId}`}>
                                {subCat.categoryName}
                            </Link>
                        </li>
                    )}
                </ul>}
        </div>
    </Spin>;
}
