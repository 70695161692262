import { Layout } from 'antd';
import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { routes } from 'theme/libs/routes';
import { NavMobile } from '../Nav/NavMobile';
import './MainPageLayout.styles.less';
import { MainPageLayoutProps } from "./MainPageLayout.types";

export const MainPageLayoutComponent = (props:MainPageLayoutProps & RouteComponentProps<any>) => {
    React.useEffect(props.closeNav, [props.location]);

    return <section className={`ant-layout ${props.isNavOpen ? 'nav-open' : ''}`}>
        <Layout.Header className="header">
            <NavMobile />
        </Layout.Header>
        <Layout.Content>
            <Switch>
                {routes.map((route: any) =>
                    <Route exact={route.exact || false} key={route.route || '404'} path={route.route} component={route.component} />
                )}
            </Switch>
        </Layout.Content>
    </section>;
}