import { connect } from 'react-redux';
import { ProductVideoComponent } from './ProductVideo.component';
import {IProductVideoDispatchProps, IProductVideoProps, IProductVideoStateProps, ProductVideoProps} from "./ProductVideo.types";
// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IProductVideoProps):IProductVideoStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IProductVideoProps):IProductVideoDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IProductVideoStateProps, dispatch:IProductVideoDispatchProps, props:IProductVideoProps):ProductVideoProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const ProductVideo = connect<IProductVideoStateProps, IProductVideoDispatchProps, IProductVideoProps, ProductVideoProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ProductVideoComponent);