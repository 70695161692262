import { Col, Layout, Row } from 'antd';
import { DealerLocatorInputGroup } from 'lemans-brandsites-common/components/DealerLocatorInputGroup';
import { DealerLocatorInputParams } from 'lemans-brandsites-common/components/DealerLocatorInputGroup/DealerLocatorInputGroup.types';
import { DealerLocatorMap } from 'lemans-brandsites-common/components/DealerLocatorMap';
import { mapTheme } from 'lemans-brandsites-common/components/DealerLocatorMap/DealerLocatorMap.types';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { dealer } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/Dealer';
import { IDealerData } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/OnlineDealer/onlineDealer.types';
import * as React from 'react';
import { useState } from 'react';
import { brandName, devMapsKey, prodMapsKey } from "../../libs/config";
import logoWithBlurb from "../../media/images/mud_logo_with_blurb.jpg";
import { FourOhFour } from '../FourOhFour';
import { DealerListItem } from './DealerListItem';
import './DealerLocator.styles.less';
import { DealerLocatorProps } from "./DealerLocator.types";

export const DealerLocatorComponent = (props: DealerLocatorProps) => {
    const { Content } = Layout;

    const [displayFourOhFour, setDisplayFourOhFour] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        activeDealerList: undefined as IDealerData[] | undefined,
        dealerList: undefined as IDealerData[] | undefined,
        offPageDealerList: undefined as IDealerData[] | undefined,
        selectedDealerIndex: undefined as number | undefined
    });

    // Set Google Maps API key
    const googleMapsKey = process.env.NODE_ENV === 'production' ?
        prodMapsKey :
        devMapsKey;

    /** Updates selected index on dealer header click */
    const onItemClick = (index: number) => {
        setState({ ...state, selectedDealerIndex: index });
    }

    /** onSearch event handler for the dealer locator input group */
    const onSearch = async (params: DealerLocatorInputParams) => {
        setIsLoading(true);
        setState({
            ...state,
            activeDealerList: undefined,
            dealerList: undefined,
            offPageDealerList: undefined,
            selectedDealerIndex: undefined
        });

        try {
            const data = await dealer(apiConfig, params);

            if (!data) {
                setDisplayFourOhFour(true);
            }

            if (!Array.isArray(data)) {
                throw new Error('An error was returned from the Dealer Locator Service.');
            }

            const activeDealerList = data.length > 5 ? data.slice(0, 5) : data;
            const offPageDealerList = data.length > 5 ? data.slice(5) : undefined;

            setState({
                ...state,
                activeDealerList,
                dealerList: data,
                offPageDealerList,
                selectedDealerIndex: undefined
            });
        }
        catch {
            setDisplayFourOhFour(true);
        }
        finally {
            setIsLoading(false);
        }
    }

    const showOnlineDealers = () => props.history.push('/online-dealers');

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { !!state &&
                <Page
                    description={`${brandName} - Dealer Locator`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Dealer Locator`}
                >
                    <Layout className="DealerLocator">
                        <Content>
                            <Row>
                                <Col xs={{ span: 24, order: 1 }} lg={{ span: 8, order: 2 }}>
                                    {isLoading && !state.dealerList && <SpinnerCss />}
                                    {!isLoading &&
                                        <div className="dealer-list">
                                            <div className="logo-with-blurb-wrapper">
                                                <a href="/">
                                                    <img className="logo-with-blurb" src={logoWithBlurb} alt={`${brandName} - Demand the Brand`} />
                                                </a>
                                            </div>
                                            <h1>Find a Dealer</h1>
                                            <DealerLocatorInputGroup
                                                apiConfig={apiConfig}
                                                onOnlineDealerClick={showOnlineDealers}
                                                className={{
                                                    button: {
                                                        onlineDealers: 'styled-button-wrapper--button',
                                                        search: 'styled-button-wrapper--button'
                                                    },
                                                    dropdown: 'styled-option-wrapper--div',
                                                    input: 'styled-text-box-wrapper--input',
                                                    radio: 'styled-radio-wrapper--radio',
                                                    select: 'styled-select-wrapper--div',
                                                }}
                                                onSearch={onSearch}
                                            />
                                            <div className={'dealer-locator-results--div'}>
                                                {state.dealerList && state.dealerList.map((item, index) =>
                                                    <DealerListItem
                                                        dealerData={item}
                                                        index={index}
                                                        key={index}
                                                        onClick={onItemClick}
                                                    />
                                                )
                                                }
                                            </div>
                                        </div>
                                    }
                                </Col>
                                <Col className="map" xs={{ span: 24, order: 2 }} lg={{ span: 16, order: 1 }}>
                                    <DealerLocatorMap
                                        apiKey={googleMapsKey}
                                        dealerList={state.dealerList}
                                        featuredMarkerDir={'//assets-static.lemansnet.com/common/img/dealerLocator/ams_markers/'}
                                        featuredMarkerImageName={'dlr_locator_feature.png'}
                                        selectedDealerIndex={state.selectedDealerIndex}
                                        theme={mapTheme.Dark}
                                    />
                                </Col>
                            </Row>
                        </Content>
                    </Layout>
                </Page>
            }
        </>
    )
};
