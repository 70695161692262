import * as React from 'react';
import './VideoList.styles.less';
import { VideoListProps } from "./VideoList.types";

export const VideoListComponent = (props: VideoListProps) =>
    <ul className="video-list">
        {
            props.videos && props.videos.length
                ? props.videos.map((video: any, i: number) => (
                    <li className="video" key={i} onClick={props.handleVideoThumbnailClick(i)}>
                        <img className="video__thumbnail" src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
                        <div className="video__title-wrapper">
                            <div className="video__title">{video.snippet.title}</div>
                        </div>
                    </li>
                ))
                : null
        }
    </ul>
