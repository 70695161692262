import { connect  } from "react-redux";
import { FitmentSearchInputGroupComponent  } from "./FitmentSearchInputGroup.component";
import { FitmentSearchInputGroupProps, IFitmentSearchInputGroupDispatchProps, IFitmentSearchInputGroupProps, IFitmentSearchInputGroupStateProps } from "./FitmentSearchInputGroup.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (state:any, props:IFitmentSearchInputGroupProps):IFitmentSearchInputGroupStateProps => ({

});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (dispatch:any, props:IFitmentSearchInputGroupProps):IFitmentSearchInputGroupDispatchProps => ({

});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (state:IFitmentSearchInputGroupStateProps, dispatch:IFitmentSearchInputGroupDispatchProps, props:IFitmentSearchInputGroupProps):FitmentSearchInputGroupProps => ({
    ...state,
    ...dispatch,
    ...props,
});

export const FitmentSearchInputGroup = connect<IFitmentSearchInputGroupStateProps, IFitmentSearchInputGroupDispatchProps, IFitmentSearchInputGroupProps, FitmentSearchInputGroupProps, any>(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(FitmentSearchInputGroupComponent);