import { connect } from "react-redux";
import { ContactComponent } from "./Contact.component";
import {
  ContactProps,
  IContactDispatchProps,
  IContactProps,
  IContactStateProps,
} from "./Contact.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: IContactProps
): IContactStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: IContactProps
): IContactDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: IContactStateProps,
  dispatch: IContactDispatchProps,
  props: IContactProps
): ContactProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const Contact = connect<
  IContactStateProps,
  IContactDispatchProps,
  IContactProps,
  ContactProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ContactComponent);
