import { catalogTemplate } from 'theme/libs/config';
import { api } from '../api';
import { feedParameters } from '../FeedParameters';
import { IResponseData } from './catalog.types';

export const catalog = async () => {
    const feedParams = await feedParameters();
    const res = await getCatalog(feedParams);

    const body = res.body.root[0].domain[0].semantic.filter( semData => semData.semanticName === 'body')[0].catalogs[0];

    return body;
} 

const getCatalog = (feedParams:any):IResponseData => 
    api.get('lemans/api/feed-info/masterFeed')
        .query(feedParams)
        .query({
            locale: 'en',
            templateName: catalogTemplate
        });
    
