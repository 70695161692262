import * as React from 'react';
import { Link } from 'react-router-dom';
import 'theme/components/SelectedFilters/SelectedFilters.styles.less';
import { SelectedFiltersProps } from "./SelectedFilters.types";

export const SelectedFiltersComponent = (props:SelectedFiltersProps) => {
    const removeURLParameter = (valueToRemove:string) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.forEach((value, key) => {
            if(valueToRemove === value) {
                queryParams.delete(key);
            }
        });
        return decodeURIComponent(window.location.pathname + '?' + queryParams.toString());
    }

    const onSearch = async () => {
        if(props.onSearch) {
            props.onSearch();
        }
    }

    return (        
        <>
        <div className="selectedFilterTitle">Selected Filters</div>
            <div className="selectedFiltersContainer" key="key-selectedFiltersContainer">
                {new URLSearchParams(window.location.search).get('q') && 
                    <span className="searchFilter">Keyword: {new URLSearchParams(window.location.search).get('q')}</span>
                }
                { props.facetHeader && props.facetHeader.facets.filter((res:any) => res.type !== 'ci').map((selectedFacet:any, index:number) =>
                    selectedFacet.values && selectedFacet.values.length > 0 &&
                        selectedFacet.values.map((selectedFacetValue:any, selectedFacetValueIndex:number) =>
                            <div className="individualSearchFilter" key={`individualSearchFilter${selectedFacetValueIndex}`}>
                                <span className="searchFilter" key={`key-${selectedFacetValueIndex}`} >{selectedFacet.name}: <span dangerouslySetInnerHTML={{__html:selectedFacetValue.name}}/></span>
                                <Link className="removeFilter" key={`removeFilter-${selectedFacetValueIndex}`} to={removeURLParameter(selectedFacetValue.id)} >
                                    <span key={`remove-${selectedFacetValueIndex}`} id={selectedFacet.type} onClick={onSearch}>remove</span>
                                </Link>
                            </div>
                        )
                )
                }
            </div>
        </>
    );
}
