import { Button, Select, Spin } from 'antd';
import { getState } from 'lemans-brandsites-common';
import { api } from 'lemans-brandsites-common/libs/api';
import * as React from "react";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './FitmentSearchInputGroup.styles.less';
import { FitmentSearchInputGroupProps } from "./FitmentSearchInputGroup.types";

export const FitmentSearchInputGroupComponent = (props: FitmentSearchInputGroupProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [firstRenderLoading, setFirstRenderLoading] = useState(true);

    const queryParams = new URLSearchParams(window.location.search);

    const state = getState({
        docType: props.docType as string,
        makeList: [] as any,
        md: queryParams.has('md') ? queryParams.get('md')!.toString() : '',
        mk: queryParams.has('mk') ? queryParams.get('mk')!.toString() : '',
        mmyData: [] as any,
        mmyResults: [] as any,
        modelList: [] as any,
        queryParams: undefined as URLSearchParams | undefined,
        yearList: [] as any,
        yr: queryParams.has('yr') ? queryParams.get('yr')!.toString() : '',
    });

    useEffect(() => {
        state.docType.set(props.docType ? props.docType : 'productGroup')
    }, []);

    // Modify state once the url has changed (when the user removes one/more selected filters from the results)
    useEffect(() => {
        if (state.mk.value !== (queryParams.has('mk') ? queryParams.get('mk')!.toString() : '')) {
            state.mk.set(queryParams.has('mk') ? queryParams.get('mk')!.toString() : '')
        }

        if (state.md.value !== (queryParams.has('md') ? queryParams.get('md')!.toString() : '')) {
            state.md.set(queryParams.has('md') ? queryParams.get('md')!.toString() : '')
        }

        if (state.yr.value !== (queryParams.has('yr') ? queryParams.get('yr')!.toString() : '')) {
            state.yr.set(queryParams.has('yr') ? queryParams.get('yr')!.toString() : '')
        }
    }, [window.location.search]);

    useEffect(() => {
        getMmyData();
    }, [state.yr.value, state.mk.value, state.md.value]);

    const getMmyData = async () => {
        setIsLoading(true);
        try {
            const data = await api.get(`lemans/api/feed-info/getFitment`)
                .query({ mk: state.mk.value })
                .query({ yr: state.yr.value })
                .query({ md: state.md.value })
                .query({ vehicleTypeId: { [0]: '18004', [1]: '18008',  [2]: '18009' } });
            state.mmyData.set(data.body.root[0]);
            state.makeList.set(data.body.root[0].makes);
            state.yearList.set(data.body.root[0].years);
            state.modelList.set(data.body.root[0].models);
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false);
            setFirstRenderLoading(false);
        }
    }

    const makeChangeHandler = (value: React.ReactText) => {
        state.mk.set(value.toString());
    }

    const modelChangeHandler = (value: React.ReactText) => {
        state.md.set(value.toString());
    }

    const removeMakeSelection = () => {
        state.makeList.set([]);
        state.mk.set('');
    }

    const removeModelSelection = () => {
        state.modelList.set([]);
        state.md.set('');
    }

    const removeYearSelection = () => {
        state.yearList.set([]);
        state.yr.set('');
    }

    const yearChangeHandler = (value: React.ReactText) => {
        state.yr.set(value.toString());
    }

    const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            getMmyData();
        }
    }

    const onSearch = async () => {
        setIsLoading(true);
        try {
            if (props.onSearch) {
                props.onSearch();
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false);
        }
    }

    const selectFilter = (input: string, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    const makeSelect = () =>
        <>
            {state.mk.value === '' &&
                <Select
                    value={state.mk.value === '' ? 'MAKE' : state.mk.value}
                    onChange={makeChangeHandler}
                    dropdownMatchSelectWidth={false}
                    showSearch
                    filterOption={selectFilter}
                    onInputKeyDown={onKeyUp}
                    notFoundContent={isLoading ? loadingAdHoc : undefined}
                >
                    {state.makeList.value && state.makeList.value.length > 0 && state.makeList.value.map((item: any) =>
                        <Select.Option key={item.makeId} value={item.makeId}>{item.makeName}</Select.Option>
                    )}
                </Select>
            }

            {state.mk.value !== '' &&
                <div className="select">
                    {firstRenderLoading && loadingAdHoc}
                    {!firstRenderLoading && state.makeList.value.length > 0 &&
                        <>
                            <span className="select__value">{state.makeList.value.find((make: any) => make.makeId.toString() === state.mk.value).makeName}</span>
                            <span className="select__remove" title="remove" onClick={removeMakeSelection}>X</span>
                        </>
                    }
                </div>
            }
        </>;

    const loadingAdHoc = <><Spin size="small" /><span style={{ marginLeft: '5px' }}>Loading...</span></>;

    const modelSelect = () =>
        <>
            {state.md.value === '' &&
                <Select
                    dropdownMatchSelectWidth={false}
                    filterOption={selectFilter}
                    notFoundContent={isLoading ? loadingAdHoc : undefined}
                    onChange={modelChangeHandler}
                    onInputKeyDown={onKeyUp}
                    showSearch
                    value={state.md.value === '' ? 'MODEL' : state.md.value}
                >
                    {state.modelList.value && state.modelList.value.length > 0 && state.modelList.value.map((item: any) =>
                        <Select.Option key={item.modelId} value={item.modelId}>{item.modelName}</Select.Option>
                    )}
                </Select>
            }
            {state.md.value !== '' &&
                <div className="select">
                    {firstRenderLoading && loadingAdHoc}
                    {!firstRenderLoading && state.modelList.value.length > 0 &&
                        <>
                            <span className="select__value">{state.modelList.value.find((model: any) => model.modelId.toString() === state.md.value).modelName}</span>
                            <span className="select__remove" title="remove" onClick={removeModelSelection}>X</span>
                        </>
                    }
                </div>
            }
        </>;

    const yearSelect = () =>
        <>
            {state.yr.value === '' &&
                <Select
                    dropdownMatchSelectWidth={false}
                    filterOption={selectFilter}
                    notFoundContent={isLoading ? loadingAdHoc : undefined}
                    onChange={yearChangeHandler}
                    onInputKeyDown={onKeyUp}
                    showSearch
                    value={state.yr.value === '' ? 'YEAR' : state.yr.value}
                >
                    {state.yearList.value && state.yearList.value.length > 0 && state.yearList.value.map((item: any) =>
                        <Select.Option key={item.year} value={item.year}>{item.year}</Select.Option>
                    )}
                </Select>
            }
            {state.yr.value !== '' &&
                <div className="select">
                    <span className="select__value">{state.yr.value}</span>
                    <span className="select__remove" title="remove" onClick={removeYearSelection}>X</span>
                </div>
            }
        </>;

    const instructionsSearchLink = state.mk.value !== '' || state.yr.value !== '' || state.md.value !== ''
        ? `?mk=${state.mk.value}&yr=${state.yr.value}&md=${state.md.value}&docType=${state.docType.value}`
        : '/installation-instructions';

    return (
        <>
            <h3>Search By Fitment</h3>
            <div className="fitment-search">
                {yearSelect()}
                {makeSelect()}
                {modelSelect()}
                <Link to={instructionsSearchLink} >
                    <Button
                        className="styled-button-wrapper--button"
                        onClick={onSearch}
                    >
                        Search
                    </Button>
                </Link>
            </div>
        </>
    );
}
