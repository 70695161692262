import { connect } from "react-redux";
import { BlogComponent } from "./Blog.component";
import {
  BlogProps,
  IBlogDispatchProps,
  IBlogProps,
  IBlogStateProps,
} from "./Blog.types";
// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: IBlogProps
): IBlogStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: IBlogProps
): IBlogDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: IBlogStateProps,
  dispatch: IBlogDispatchProps,
  props: IBlogProps
): BlogProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const Blog = connect<
  IBlogStateProps,
  IBlogDispatchProps,
  IBlogProps,
  BlogProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(BlogComponent);
