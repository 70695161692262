import { connect } from "react-redux";
import { nav } from 'theme/libs/nav-redux';
import { NavMobileComponent } from "./NavMobile.component";
import { INavMobileDispatchProps, INavMobileProps, INavMobileStateProps, NavMobileProps } from "./NavMobile.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: INavMobileProps
): INavMobileStateProps => ({
  isNavOpen: nav.isActive(state, false),
});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: INavMobileProps
): INavMobileDispatchProps => ({
  toggleIsNavOpen: () => {dispatch(nav.toggle(false));},
});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: INavMobileStateProps,
  dispatch: INavMobileDispatchProps,
  props: INavMobileProps
): NavMobileProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const NavMobile = connect<
  INavMobileStateProps,
  INavMobileDispatchProps,
  INavMobileProps,
  NavMobileProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NavMobileComponent);
