import { connect } from "react-redux";
import { ContactFormComponent } from "./ContactForm.component";
import {
  ContactFormProps,
  IContactFormDispatchProps,
  IContactFormProps,
  IContactFormStateProps,
} from "./ContactForm.types";

// The mapStateToProps function:  Use this to fetch data from the Redux store via selectors
export const mapStateToProps = (
  state: any,
  props: IContactFormProps
): IContactFormStateProps => ({});

// The mapDispatchToProps function:  Use this to define handlers and dispatch basic actions
export const mapDispatchToProps = (
  dispatch: any,
  props: IContactFormProps
): IContactFormDispatchProps => ({});

// The mergeProps function:  Use this to define handlers and dispatchers that require access to state props
export const mergeProps = (
  state: IContactFormStateProps,
  dispatch: IContactFormDispatchProps,
  props: IContactFormProps
): ContactFormProps => ({
  ...state,
  ...dispatch,
  ...props,
});

export const ContactForm = connect<
  IContactFormStateProps,
  IContactFormDispatchProps,
  IContactFormProps,
  ContactFormProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ContactFormComponent);
