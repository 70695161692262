import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
// import * as moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { brandName } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import { StaticTemplate } from '../StaticTemplate';
import './PrivacyPolicy.styles.less';
import { PrivacyPolicyProps } from "./PrivacyPolicy.types";

export const PrivacyPolicyComponent = (props: PrivacyPolicyProps) => {
    const [displayFourOhFour, isLoading, data] = pageRestHandleSideEffects('privacy-policy');
    // const [date, setDate] = useState<string>();
    const [content, setContent] = useState<string>();
    const [title, setTitle] = useState<string>();

    useEffect(() => {
        setContent(data?.content.rendered);
        // setDate(moment(data?.date).format('MMMM d, YYYY'));
        setTitle(data?.title.rendered);
    }, [data]);

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading && <SpinnerCss />}
            { !isLoading && !!title && !!content &&
                <Page
                    className="Privacy Policy"
                    description={`${brandName} - Privacy Policy`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Privacy Policy`}
                >
                    <StaticTemplate fullPage={true}>
                        <div className="header-with-meta-wrapper">
                            <h2 dangerouslySetInnerHTML={{ __html: title }} />
                            {/* <i dangerouslySetInnerHTML={{ __html: `Effective as of ${date}` }} /> */}
                        </div>

                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </StaticTemplate>
                </Page>
            }
        </>
    );
}
